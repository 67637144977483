<template>
<div>
  <div class="flex justify-center mt-8">
    <div class="flex justify-between">
      <div class="flex space-x-2.5">
        <button
            @click="prevPage"
            :disabled="currentPage <2"
            class="p-2  rounded-full border-2 border-blue"
        >
          <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M12 18L6 12L12 6"
                stroke="#331B3B"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M19 18L13 12L19 6"
                stroke="#EEF2FF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
          </svg>
        </button>
        <div class="flex">
          <div
              v-for="item in activePages"
              :key="item"
              @click="myPage(item)"
          >
            <button :class=" item === currentPage
             ? 'rounded-full bg-[#2D3134] text-white p-4 border-2' : 'bg-white'" class="mx-2 rounded-full p-4 border-2">
              {{ item }}
            </button>
          </div>
        </div>
        <button
            :disabled="currentPage >= this.myLastPage"
            @click="nextPage"
            class="p-2  rounded-full border-2 p-2"
        >
          <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M12 18L18 12L12 6"
                stroke="#331B3B"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M5 18L11 12L5 6"
                stroke="#EEF2FF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "PaginationNews",
  data() {
   return{
     currentPage: 1,
     activePages: [1,2,3]
   }
  },
  props: ['myLastPage', 'myCurrentPage'],
  methods: {
    prevPage () {
      this.currentPage--;
      this.$emit('myEvent', this.currentPage)
    },
    nextPage () {
      this.currentPage++;
      this.$emit('myEvent', this.currentPage)
    },
    myPage (currentPage) {
      this.currentPage = currentPage;
      this.$emit('myEvent', this.currentPage)
    },
  },
  watch: {
    currentPage(){
      if(this.myLastPage > 3) {
        if( this.currentPage > 1 && this.currentPage < this.myLastPage){
          this.activePages = [this.currentPage-1, this.currentPage, this.currentPage+1]
        } else if(this.currentPage === 1) {
          this.activePages = [1,2,3]
        } else if(this.currentPage === this.myLastPage) {
          this.activePages = [this.myLastPage-2,this.myLastPage-1,this.myLastPage]
        }
      }
    },
    myCurrentPage() {
      if ( this.myCurrentPage === 1 ) {
        this.currentPage = 1
        this.$emit('myEvent', this.currentPage)
      }
    },
    myLastPage() {
        if( this.myLastPage === 1) {
          this.activePages = [1]
        } else if (this.myLastPage === 2) {
          this.activePages = [1,2]
        } else if (this.myLastPage >= 3) {
          this.activePages = [1,2,3]
        }
    }
  },
}
</script>

<style scoped>

</style>