<template>
  <div class="bg-[#F7FAF9] md:mb-[96px] md:mb-[48px] mb-6 md:pt-[60px] pt-[30px] md:pb-[70px] pb-[30px]">
    <div class="grid-container select-none">
      <Heading uk-scrollspy="cls: uk-animation-slide-left-medium; delay:300; repeat: false">
        <template #menu> {{ $t('news.title') }} </template>
        <template #title>
        </template>
      </Heading>
      <swiper
          uk-scrollspy="cls: uk-animation-slide-top-medium; delay:300; repeat: false; autoplay: true;"
          :slides-per-view="3"
          :space-between="30"
          :breakpoints="swiperOptions.breakpoints"
          :modules="modules"
          :autoplay="true"
          navigation
          :pagination="{ clickable: true }"
          :scrollbar="{ draggable: false }"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
      >
        <swiper-slide v-for="(u , index) in newsTable" :key="index">
          <router-link :to="`/newscontent/${u.id}`" class="hover:no-underline">
            <NewsCard :img="getImage(u.cover_img_files_id, u.cover_img_files_uid)" :name="u.title" :title="u.subtitle" :id="u.id" />
          </router-link>
        </swiper-slide>
      </swiper>
      <div class="flex justify-center">
        <router-link to="news" class="hover:no-underline hover:text-white">
          <div class="md:px-[41px] px-8 py-4 bg-[#2D6D62] rounded-[27px] font-bold text-lg text-white flex justify-center">
            {{ $t('news.button') }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, A11y } from "swiper";
import "swiper/css/navigation";
// import "swiper/css/pagination";
// import 'swiper/css/scrollbar';
import "swiper/css";

import news_a from '@/assets/images/news_a.png'
import news_b from '@/assets/images/news_b.png'
import news_c from '@/assets/images/news_c.png'

import Heading from "@/components/Blocks/Heading";
import NewsCard from "@/components/Blocks/NewsCard";
import axios from 'axios';

export default {
  name: "News",
  components: {
    Swiper,
    SwiperSlide,
    Heading,
    NewsCard,
  },
  setup() {
    return {
      modules: [Navigation, A11y],
    };
  },
  // data: () => ({
  //   currentLang: 'uk',
  //   newsTable: [],
  //   swiperOptions: {
  //     breakpoints: {
  //       320: {
  //         slidesPerView: 1,
  //         spaceBetween: 10
  //       },
  //       770: {
  //         slidesPerView: 2,
  //         spaceBetween: 50
  //       },
  //       871: {
  //         slidesPerView: 3,
  //         spaceBetween: 30
  //       }
  //     },
  //   },
  // news: [
  //     {
  //         id: 1,
  //         img: news_a,
  //         name: "Бизнинг бренд тўғрисида биласизми?",
  //         title: "TANWIR – нур таратувчи, нур, ёруғлик улашувчи, маърифат, таълим деган маъноларни...",
  //         button_url: "javascript:void(0)"
  //     },
  //     {
  //         id: 2,
  //         img: news_b,
  //         name: "Our new brand outfits",
  //         title: "Here are lots of interesting destinations to visit, but don't be confused—they already grouped by category.",
  //         button_url: "javascript:void(0)"
  //     },
  //     {
  //         id: 3,
  //         img: news_c,
  //         name: "Our new brand outfits",
  //         title: "Here are lots of interesting destinations to visit, but don't be confused—they already grouped by category.",
  //         button_url: "javascript:void(0)"
  //     },
  //     {
  //         id: 4,
  //         img: news_a,
  //         name: "Our new brand outfits",
  //         title: "Here are lots of interesting destinations to visit, but don't be confused—they already grouped by category.",
  //         button_url: "javascript:void(0)"
  //     },
  //     {
  //         id: 5,
  //         img: news_b,
  //         name: "Our new brand outfits",
  //         title: "Here are lots of interesting destinations to visit, but don't be confused—they already grouped by category.",
  //         button_url: "javascript:void(0)"
  //     },
  // ]
  // }),
  data() {
    return{
      currentLang: 'uk',
      newsTable: [],
      swiperOptions: {
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          770: {
            slidesPerView: 2,
            spaceBetween: 50
          },
          871: {
            slidesPerView: 3,
            spaceBetween: 30
          }
        },
      },
    }
  },
  methods: {
    init() {
      axios
          .get('https://tanwir.uz:6443/gen/publications/view?publication_lang=' + this.currentLang+'&status=true',  {
            //   headers: {
            //     Authorization: 'Bearer ' + localStorage.getItem('token')
            //   }
          })
          .then(response => {
            this.newsTable = response.data.list
            console.log(response.data)
          })
          .catch((err) => {
            // console.log(err.response.status)
            //   if(err.response.status === 401) {
            //     router.push('/')
            //   }
          });
    },
    dateTime(value) {
      return moment(value).format("DD-MM-YYYY, h:mm:ss a");
    },
    getImage(id, uid) {
      return 'https://tanwir.uz:6443/gen/files/'+id+'/'+uid+'/view'
    }
  },
  watch: {
    currentLang() {
      this.init()
    }
  },
  mounted() {
    window.addEventListener('lang-changed', (event) => {
      this.currentLang = event.detail.storage;
    });
    this.init()
  },
  created() {
    if(localStorage.getItem('lang'))
      this.currentLang = localStorage.getItem('lang')
  }
};
</script>

<style>
.swiper-button-prev:after {
  content: url("@/assets/images/icons/prev_button.svg");
}
.swiper-button-next:after {
  content: url("@/assets/images/icons/next_button.svg");
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 60px !important;
  top: 0;
  top: 30px;
  left: auto !important;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 1010px !important;
  top: 30px;
  margin-bottom: 10px !important;
}
</style>
