<template>
    <div class="grid-container bg-[#F7FAF9] rounded-2xl flex flex-col space-y-9 px-8 pt-6 pb-12 max-md:px-6">
        <div>
            <p class="text-xl text-[#070303] font-bold max-md:tex-lg max-sm:text-base" uk-scrollspy="cls: uk-animation-slide-left-medium; delay:300; repeat: false">{{ $t('payment.type.text') }}</p>
        </div>
        <div class="flex flex-col space-y-5 font-bold text-base text-[#36454F] max-md:text-sm max-sm:text-xs" uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: .tour__type; delay:300; repeat: false">
            <div class="flex space-x-24 max-md:grid max-md:grid-cols-2 max-md:space-x-0 max-md:gap-x-6 max-md:place-items-center">
                <div class="flex space-x-4 max-sm:flex-col max-sm:space-x-0 max-sm:items-center tour__type">
                    <img class="max-lg:w-12 max-lg:h-12 max-md:w-8 max-md:h-8 max-sm:w-8 max-sm:h-8" src="@/assets/images/icons/payment/uzcard.svg" alt="">
                    <p class="self-center">Uzcard & Humo</p>
                </div>
                <div class="flex space-x-4 max-sm:flex-col max-sm:space-x-0 max-sm:items-center max-md:place-items-center tour__type">
                    <img class="max-lg:w-12 max-lg:h-12 max-md:w-8 max-md:h-8 max-sm:w-8 max-sm:h-8" src="@/assets/images/icons/payment/mastercard.svg" alt="">
                    <div class="self-center max-sm:text-center">
                        <p>International</p>
                        <p>payment system</p>
                    </div>
                </div>
            </div>
            <div class="flex space-x-24 max-md:grid max-md:grid-cols-2 max-md:space-x-0 max-md:gap-x-6 max-md:place-items-start max-sm:place-items-center">
                <div class="flex space-x-4 max-sm:flex-col max-sm:space-x-0 max-sm:items-center max-md:place-self-center tour__type">
                    <img class="max-lg:w-12 max-lg:h-12 max-md:w-8 max-md:h-8 max-sm:w-8 max-sm:h-8" src="@/assets/images/icons/payment/click.svg" alt="">
                    <p class="self-center">Click evolution</p>
                </div>
                <div class="flex space-x-4 max-sm:flex-col max-sm:space-x-0 max-sm:items-center tour__type">
                    <img class="max-lg:w-12 max-lg:h-12 max-md:w-8 max-md:h-8 max-sm:w-8 max-sm:h-8" src="@/assets/images/icons/payment/money.svg" alt="">
                    <p class="self-center">{{ $t('payment.type.text1') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PaymentType"
}
</script>

<style scoped>

</style>