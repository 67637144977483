<template>
  <div>
    <div class="z-10" id="plan" ref="plan">
      <Heading class="grid-container md:mb-[70px] mb-4" uk-scrollspy="cls: uk-animation-slide-left-medium; delay:300; repeat: false">
        <template #menu>
          {{ $t('plans.title') }}
        </template>
        <template #title>
          {{ $t('plans.text') }}.
        </template>
      </Heading>
      <PostCard :posts="posts"/>
    </div>
  </div>
</template>

<script>
import Heading from '@/components/Blocks/Heading.vue';
import PostCard from '@/components/Blocks/PostCard.vue';

import axios from "axios";
export default {
  name: "Plans",
  components: {
    Heading,
    PostCard,
  },
  data: () => ({
    currentLang: 'uk',
    posts: [],
  }),
  methods: {
    init() {
      axios
          .get('https://tanwir.uz:6443/dic/service-types',
              {
                  headers: {
                    lang: this.currentLang
                  }
              })
          .then(response => {
            this.posts = response.data.list
            console.log(response.data)
          })
          .catch((err) => {
            // console.log(err.response.status)
            //   if(err.response.status === 401) {
            //     router.push('/')
            //   }
          });
    },
    getImage(id, uid) {
      return 'https://tanwir.uz:6443/gen/files/'+id+'/'+uid+'/view'
    }
  },
  watch: {
    currentLang() {
      this.init()
    }
  },
  mounted() {
    window.addEventListener('lang-changed', (event) => {
      this.currentLang = event.detail.storage;
    });
    this.init()
  },
  created() {
    if(localStorage.getItem('lang'))
      this.currentLang = localStorage.getItem('lang')
  }
}
</script>

<style>

</style>