<template>
  <div>
    <div class="uk-position-relative uk-visible-toggle uk-light lg:mb-[98px] md:mb-[36px] sm:mb-6 mb-6 lg:mt-[46px] md:mt-[46px] sm:mt-6 mt-6" tabindex="-1" uk-slider="clsActivated: uk-transition-active; center: true; autoplay: true; autoplay-interval: 13000; pause-on-hover: false">
      <ul class="uk-slider-items uk-grid">
        <li class="uk-width-4-4">
          <div class="uk-panel">
            <div class="grid-container">
              <div class="flex lg:flex-row md:flex-row sm:flex-col flex-col justify-between">
                <div
                    class="md:mr-[85px]"
                    uk-scrollspy="cls: uk-animation-slide-left-medium; delay:300; repeat: true"
                >
                  <img
                      class="w-[145px] h-[49px] lg:mb-0 md:mb-0 sm:mb-4 mb-4"
                      src="@/assets/images/icons/arabic_word.svg"
                      alt=""
                  />
                  <div
                      class="font-bold lg:text-[55px] md:text-[30px] sm:text-4xl text-4xl text-[#2D6D62]
                                  lg:leading-[85px] md:leading-[65px] sm:leading-[55px] leading-[55px] 
                                  lg:text-start md:text-start sm:text-start text-start
                                  md:mb-3"
                  >
                    "{{ $t('header.surah1') }}."
                  </div>
                  <div class="font-medium md:text-base text-sm text-[#2D6D62] lg:mb-8 md:mb-8 sm:mb-4 mb-4 text-end">
                    ({{ $t('header.subtitle1') }}).
                  </div>
                  <div
                      class="font-medium lg:text-lg md:text-lg sm:text-lg text-sm text-[#5B5F62] lg:mb-[32px] md:mb-[32px] sm:mb-6 mb-6 text-start"
                  >
                    “{{ $t('header.addition1') }}”.
                  </div>
                  <div class="flex md:justify-start justify-center">
                    <router-link
                        to="about"
                        :class="[
                                'hover:no-underline',
                                content === true ? 'hover:text-white' : 'hover:text-[#112827]',
                              ]"
                        href="javascript:void(0)"
                    >
                      <div
                          class="font-medium text-base text-[#112827] leading-4 py-[19px] px-[27px] flex justify-center
                                    rounded-[27px] border-[1.4px] border-[#112827] cursor-pointer lg:mb-0 md:mb-4 sm:mb-6 mb-6"
                      >
                        {{ $t('header.more') }}...
                      </div>
                    </router-link>
                  </div>
                </div>
                <img
                    uk-scrollspy="cls: uk-animation-slide-right-medium; repeat: true"
                    class="lg:w-[571px] md:w-[521px] sm:w-[500px] w-[342px]
                                lg:h-[552px] md:h-[501px] sm:h-[450px] h-[331px] object-cover 
                                lg:rounded-[64px] md:rounded-[64px] sm:rounded-3xl rounded-3xl"
                    src="@/assets/images/kaba.png"
                    alt=""
                />
              </div>
            </div>
          </div>
        </li>
        <li class="uk-width-4-4">
          <div class="uk-panel">
            <div class="grid-container">
              <div class="flex lg:flex-row md:flex-row sm:flex-col flex-col justify-between">
                <div
                    class="md:mr-[85px]"
                    uk-scrollspy="cls: uk-animation-slide-left-medium; delay:300; repeat: true"
                >
                  <img
                      class="w-[145px] h-[49px] lg:mb-6 md:mb-6 sm:mb-4 mb-4"
                      src="@/assets/images/icons/arabic_word.svg"
                      alt=""
                  />
                  <div
                      class="font-bold lg:text-[40px] text-[30px] text-[#2D6D62] lg:leading-[60px] md:text-start text-start"
                  >
                    "{{ $t('header.surah2') }}"
                  </div>
                  <div class="font-medium md:text-base text-sm text-[#2D6D62] lg:mb-8 md:mb-8 sm:mb-4 mb-4 text-end">
                    ({{ $t('header.subtitle2') }}).
                  </div>
                  <div class="flex md:justify-start justify-center">
                    <router-link
                        to="about"
                        :class="[
                                'hover:no-underline',
                                content === true ? 'hover:text-white' : 'hover:text-[#112827]',
                              ]"
                        href="javascript:void(0)"
                    >
                      <div
                          class="font-medium text-base text-[#112827] leading-4 py-[19px] px-[27px] flex justify-center
                                rounded-[27px] border-[1.4px] border-[#112827] cursor-pointer lg:mb-0 md:mb-4 sm:mb-6 mb-6"
                      >
                        {{ $t('header.more') }}...
                      </div>
                    </router-link>
                  </div>
                </div>
                <img
                    uk-scrollspy="cls: uk-animation-slide-right-medium; repeat: true"
                    class="lg:w-[571px] md:w-[521px] sm:w-[500px] w-[342px]
                          lg:h-[552px] md:h-[501px] sm:h-[450px] h-[331px] object-cover 
                          lg:rounded-[64px] md:rounded-[64px] sm:rounded-3xl rounded-3xl"
                    src="@/assets/images/slide_a_converted.jpg"
                    alt=""
                />
              </div>
            </div>
          </div>
        </li>
        <li class="uk-width-4-4">
          <div class="uk-panel">
            <div class="grid-container">
              <div class="flex lg:flex-row md:flex-row sm:flex-col flex-col justify-between">
                <div
                    class="md:mr-[85px]"
                    uk-scrollspy="cls: uk-animation-slide-left-medium; delay:300; repeat: true"
                >
                  <img
                      class="w-[145px] h-[49px] lg:mb-6 md:mb-6 sm:mb-4 mb-4"
                      src="@/assets/images/icons/arabic_word.svg"
                      alt=""
                  />
                  <div
                      class="font-bold lg:text-[32px] text-[24px] text-[#2D6D62] lg:leading-[48px] md:text-start text-start"
                  >
                    "{{ $t('header.surah3') }}"
                  </div>
                  <div class="font-medium md:text-base text-sm text-[#2D6D62] lg:mb-8 md:mb-8 sm:mb-4 mb-4 text-end">
                    ({{ $t('header.subtitle3') }}).
                  </div>
                  <div class="flex md:justify-start justify-center">
                    <router-link
                        to="about"
                        :class="[
                                'hover:no-underline',
                                content === true ? 'hover:text-white' : 'hover:text-[#112827]',
                              ]"
                        href="javascript:void(0)"
                    >
                      <div
                          class="font-medium text-base text-[#112827] leading-4 py-[19px] px-[27px] flex justify-center
                                rounded-[27px] border-[1.4px] border-[#112827] cursor-pointer lg:mb-0 md:mb-4 sm:mb-6 mb-6"
                      >
                        {{ $t('header.more') }}...
                      </div>
                    </router-link>
                  </div>
                </div>
                <img
                    uk-scrollspy="cls: uk-animation-slide-right-medium; repeat: true"
                    class="lg:w-[571px] md:w-[521px] sm:w-[500px] w-[342px]
                          lg:h-[552px] md:h-[501px] sm:h-[450px] h-[331px] object-cover 
                          lg:rounded-[64px] md:rounded-[64px] sm:rounded-3xl rounded-3xl"
                    src="@/assets/images/slide_b_converted.jpg"
                    alt=""
                />
              </div>
            </div>
          </div>
        </li>
      </ul>
      <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
      <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>
    </div>
  </div>
</template>

<script>

export default {
  name: "Header",
};
</script>

<style>
.uk-slidenav {
  padding: 5px 10px;
  color: rgba(102,102,102,.5)!important;
  transition: color .1s ease-in-out;
}

.uk-position-center-left {
  content: url("@/assets/images/icons/header_prev.svg");
  margin-left: 45px!important;
}
.uk-position-center-right {
  content: url("@/assets/images/icons/header_next.svg");
  margin-right: 45px!important;
}

.uk-visible-toggle:not(:hover):not(:focus) .uk-hidden-hover:not(:focus-within) {
  position: absolute!important;
  width: 0!important;
  height: 0!important;
  padding: 0!important;
  margin: 0!important;
  overflow: visible!important;
}
.chat_button {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 47px;
  margin-bottom: 39px;
  z-index: 9999;
}
.chat_button::before {
  content: "";
  margin-right: 2px;
  border-radius: 999999px;
  width: 100%;
  height: 100%;
  background-color: #17B288;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  animation: ping 1s cubic-bezier(0, 0, 0.4, 1) infinite;
  z-index: -1;
}
</style>
