<template>
    <div ref="chatBot" class="chat__container open">
      <div class="chat__modal my__chat__modal__block">
        <audio id="bell">
          <source src="@/assets/bell.ogg" />
        </audio>
        <div class="chat__modal__container">
          <div class="chat__modal__block">
            <div class="chat__modal__header">
              <div class="chat__modal__header__question">
                <img alt="question" src="@/assets/question.svg" />
              </div>
              <div class="chat__modal__header__logo">
                <img alt="logo" src="@/assets/green_logo.jpg" />
              </div>
              <div :class="{ '-red': !online }" class="chat__modal__header__heading">
                <div class="description"></div>
                <div class="title" v-if="online">
                  Online  {{ $t('chatButton.title') }}
                </div>
                <div class="title" v-if="!online">
                  Offline
                </div>
              </div>
              <div class="chat__modal__close__container">
                <button @click="closeButton()" class="chat__modal__close" type="button">
                  <svg
                    height="9.316"
                    viewBox="0 0 16.335 9.316"
                    width="16.335"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0,1.085c0,.651.313.871.79,1.348.788.788,6.292,6.34,6.6,6.578a1.031,1.031,0,0,0,.843.305c.519,0,.9-.466,1.22-.79L12.5,5.48c.287-.287,3.346-3.313,3.531-3.551a1.031,1.031,0,0,0,.305-.843A1.139,1.139,0,0,0,15.25,0a1.49,1.49,0,0,0-1.188.63c-.578.578-5.71,5.755-5.894,5.878-.179-.12-5.3-5.288-5.878-5.862C1.886.243,1.667,0,1.085,0A1.139,1.139,0,0,0,0,1.085Z"
                      fill="#152c69"
                      fill-rule="evenodd"
                      transform="translate(0)"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <!--                    <div v-if="!online" class="flex justify-center items-center chat__modal__body">-->
            <!--                        <p class="offline-title">-->
            <!--                            Hozirda operatorlar offline. Ish vaqtimiz 9:00-18:00 gacha-->
            <!--                        </p>-->
            <!--                    </div>-->
            <div
              v-if="online"
              :class="{ 'flex justify-center items-center': !isAuth }"
              class="chat__modal__body"
            >
              <div v-if="!this.isAuth && online" class="registration">
                <div>
                  <div class="user__block">
                    <img alt="user" src="@/assets/user.svg" />
                  </div>
                </div>
                <div class="title">
                  {{$t('chatButton.text')}}
                </div>
                <div :class="{ required: error.fio }" class="auth__block">
                  <div v-if="error.fio" class="required-title" style="margin-bottom: 6px">
                    {{$t('chatButton.nameError') }}
                  </div>
                  <input
                    v-model="registration.fio"
                    :placeholder="$t('chatButton.placeholder')"
                    required
                    type="text"
                  />
                </div>
                <div :class="{ required: error.phone }" class="auth__block">
                  <div v-if="error.phone" class="required-title">
                    {{$t('chatButton.phoneError')}}
                  </div>
                  <div class="registration__phone">
                    <span>+</span>
                    <input
                      v-model="registration.phone"
                      max="12"
                      min="11"
                      required
                      type="number"
                      @keypress="checkPhone($event)"
                    />
                  </div>
                </div>
                <div class="auth__button">
                  <button class="registration__btn" type="button" @click="sendMessage()">
                    {{$t('chatButton.button') }}
                    <img alt="send" src="@/assets/send.svg" />
                  </button>
                </div>
              </div>
              <div v-for="(item, index) in chat" :key="index">
                <div
                  v-if="this.isAuth && online"
                  :class="{
                    operator: item.message_from_client === 0,
                    client: item.message_from_client === 1,
                  }"
                  class="message"
                >
                  <div>
                    <div v-if="item.message_from_client === 0" class="user_style">
                        <img
                          alt="tanwir"
                          src="@/assets/green_logo.jpg"
                          style="width: 45px; height: 45px; border-radius: 22.5px"
                        />
                      </div>
                  </div>
                  <div v-if="item.message" class="content">
                    <div v-if="item.operator" class="name" style="font-weight: 700">
                      {{ item.operator }}
                    </div>
                    <div class="text" style="margin-top: 0">
                      {{ item.message }}
                    </div>
                    <div class="sender">
                      <div class="time">
                        {{ getDate(item.time) }}
                      </div>
                    </div>
                  </div>
                  <div v-if="item.message_from_client === 1" class="user_style">
                    <img alt="tanwir" src="@/assets/user.svg" />
                  </div>
                </div>
              </div>
            </div>
            <!-- <div v-if="!this.isAuth && online" class="poweredBy corner-radius">
              <div class="poweredBy__text">Powered By Travel Tech 25</div>
              <div class="poweredBy__question">
                <img alt="question" src="@/assets/question.svg" />
              </div>
            </div>
            <div v-if="!online" class="poweredBy corner-radius">
              <div class="poweredBy__text">Powered By Travel Tech 25</div>
              <div class="poweredBy__question">
                <img alt="question" src="@/assets/question.svg" />
              </div>
            </div> -->
            <div v-if="this.isAuth && online" class="chat__modal__footer">
              <form
                enctype="multipart/form-data"
                @keydown.prevent.enter="sendMessage"
                @submit.prevent="sendMessage"
              >
                <div class="-input">
                  <textarea
                    ref="input"
                    v-model="form.message"
                    maxlength="400"
                    :placeholder="$t('chatButton.placeholder1')"
                    rows="1"
                    @input="showSubmitBtn"
                  ></textarea>
                  <div :class="{ invisible: !open }" class="send__container">
                    <button class="send" type="submit">
                      <img alt="send" src="@/assets/send.svg" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { onMounted, ref } from "vue";
  import { useI18n } from 'vue-i18n';
  // import { computed } from 'vue';

  export default {
    name: "Button",
    data() {
      return {
        open: false,
        isAuth: localStorage.getItem("isAuth") ?? false,
        form: {
          message: "",
          file: null,
        },
        registration: {
          phone: "998",
          fio: "",
        },
        error: {
          fio: false,
          phone: false,
        },
      };
    },
    methods: {
      getDate(value) {
        if (value) {
          const d = new Date(value);
          let hours = this.checkSingleDigit(d.getHours());
          let minutes = this.checkSingleDigit(d.getMinutes());
          return `${hours}:${minutes}`;
        }
      },
      checkPhone(value) {
        value = value ? value : window.event;
        var charCode = value.which ? value.which : value.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
          value.preventDefault();
        }
        if (String(this.registration.phone).length >= 12) {
          value.preventDefault();
        } else {
          return true;
        }
      },
      checkSingleDigit(digit) {
        return ("0" + digit).slice(-2);
      },
      // onFileChange(e) {
      //     this.form.file = e.target.files || e.dataTransfer.files;
      // },
      showSubmitBtn() {
        if (this.form.message) return (this.open = true);
        else return (this.open = false);
      },
      validate() {
        return (
          localStorage.getItem("isAuth") &&
          localStorage.getItem("fio") &&
          localStorage.getItem("phone_number")
        );
      },
      sendMessage() {
        this.error.fio = !(
          String(this.registration.fio).length >= 8 &&
          String(this.registration.fio).length <= 48
        );
        this.error.phone = String(this.registration.phone).length !== 12;
        if ((!this.error.fio && !this.error.phone) || this.validate()) {
          let message;
          if (localStorage.getItem("isAuth") && this.form.message !== "") {
            this.chat.push({
              message: this.form.message,
              message_from_client: 1,
              time: new Date(),
            });
            localStorage.setItem("chat", JSON.stringify(this.chat));
          }
          if (!localStorage.getItem("isAuth")) {
            message = {
              fio: this.registration.fio,
              phone_number: this.registration.phone,
              is_auth: 1,
            };
            localStorage.setItem("isAuth", true);
            localStorage.setItem("fio", this.registration.fio);
            localStorage.setItem("phone_number", this.registration.phone);
            this.isAuth = true;
          } else {
            message = {
              message: this.form.message,
              token: localStorage.getItem("token"),
            };
          }
          this.connection.send(JSON.stringify(message));
          this.form.message = "";
          this.open = false;
        }
      },
      closeButton() {
        this.$emit('closeButton')
      }
    },
    setup() {
      const { t } = useI18n();
      // let hello = computed(() => t.("chatButton.hello"));
      const chat = ref();
      const online = ref(true);
      if (localStorage.getItem("chat")) {
        chat.value = JSON.parse(localStorage.getItem("chat"));
      } else {
        let hi = [
          {
            message:
                t("chatButton.hello"),
            message_from_client: 0,
            time: new Date(),
            operator: "",
          },
        ];
        localStorage.setItem("chat", JSON.stringify(hi));
        chat.value = JSON.parse(localStorage.getItem("chat"));
      }
  
      const socketUrl =
        "wss://tt25.uz:8443/chatbutton/chat/" + localStorage.getItem("token");
      const connection = ref();
  
      onMounted(() => {
        connection.value = new WebSocket(socketUrl);
  
        connection.value.onmessage = function (event) {
          let data = JSON.parse(event.data);
          if (data.action === "offline") online.value = false;
  
          if (data.action === "get client") {
            localStorage.clear();
            location.reload();
          }
  
          if (data.action === "auth") localStorage.setItem("token", data.data.token);
  
          if (data.action === "expired") {
            localStorage.clear();
            location.reload();
          }
  
          if (data.status === true && data.data && data.action === "send message") {
            let message = data.data;
            chat.value.push({
              message: message.message,
              message_from_client: 0,
              time: new Date(),
              operator: message.answering_user_fio,
            });
            localStorage.setItem("chat", JSON.stringify(chat.value));
            document.getElementById("bell").play();
          }
        };
  
        // connection.value.onopen = function () {
        //   console.log(
        //     "Developers: Ismoiljon Hakimjonov, Nutfillo Timirov, Maqsud Solijonov \n" +
        //       "Telegram: @ismoil_rich, @Cyber512, @maxcybercom"
        //   );
        // };
      });
  
      return { chat, connection, online };
    },
  };
  </script>
  
  <style scoped>  
  .offline-title {
    color: #b9b9b9;
    text-align: center;
    font-weight: 700;
  }
  
  .user_style {
    width: 45px;
    height: 45px;
    background-color: #fff;
    border-radius: 9999999px;
  }
  
  .invisible {
    visibility: hidden;
  }
  
  .user_style img {
  }
  
  .chat__container.open .chat__button {
    visibility: hidden;
    opacity: 0;
  }
  
  .flex {
    display: flex;
  }
  
  .items-center {
    align-items: center;
  }
  
  .justify-center {
    justify-content: center;
  }
  
  .registration {
    background-color: #fff;
    display: flex;
    border-radius: 16px;
    padding: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .registration .title {
    color: #000;
    font-size: 14px;
    margin-bottom: 1.8rem;
    margin-top: 12px;
    font-weight: 600;
  }
  
  .registration .user__block {
    border-radius: 999999px;
    width: 59px;
    height: 59px;
    border: 1px solid rgba(111, 163, 78, 0.26);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .user__block img {
  }
  
  .registration div {
    width: 100%;
  }
  
  .registration input {
    font-size: 17px;
    border-radius: 22px;
    border: 1px solid #dddddd;
    margin-left: auto;
    margin-right: auto;
    width: 213px;
    padding: 12px 20px;
  }
  
  .registration .auth__block {
    display: flex;
    flex-direction: column;
    /* width: calc(100 % - 25 px) !important;*/
  }
  
  .auth__block.required .registration__phone {
    border-color: #ce0430;
    box-shadow: 0 0 1px 2px rgba(206, 4, 48, 0.4);
  }
  
  .required-title {
    font-size: 12px;
    color: #ce0430;
    margin: 8px auto -4px auto;
    text-align: left;
    max-width: 233px;
  }
  
  .auth__block.required > input {
    border-color: #ce0430;
    box-shadow: 0 0 1px 2px rgba(206, 4, 48, 0.4);
  }
  
  .registration__btn img {
    margin-left: 0.5rem;
  }
  
  .registration__btn {
    background-color: #6fa34e;
    border-radius: 22px;
    border: none;
    color: #ffffff;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 12px 15px;
    text-transform: lowercase;
    cursor: pointer;
  }
  
  .registration__phone {
    display: flex;
    border-radius: 22px;
    border: 1px solid #dddddd;
    width: 213px !important;
    margin: 10px auto 1rem auto;
    align-items: center;
    position: relative;
    padding: 12px 20px;
  }
  
  .registration__phone input {
    border: none;
    padding: 0 !important;
    margin: 0;
    width: 100%;
    height: 100%;
  }
  
  .registration input:focus {
    outline: none;
  }
  
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  
  .chat__button {
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: lightblue;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
    margin-bottom: 30px;
    border-radius: 99999px;
  }
  
  .chat__button a {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .chat__button img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .chat__container {
  }
  
  .chat__container.open .chat__modal {
    visibility: visible;
    opacity: 1;
    z-index: 20;
  }
  
  .chat__modal {
    z-index: 20;
    position: fixed;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;
  }
  
  .chat__modal__close {
    margin-right: 25px;
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .chat__modal__close__container {
    position: relative;
    z-index: 9;
  }
  
  .chat__modal__container {
    z-index: 1;
    position: relative;
    overflow: hidden;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  
  .chat__modal__block {
    z-index: 9;
    display: flex;
    border: 1px solid #ddd;
    box-shadow: 0 0 4px 0 rgb(0 0 0 / 10%);
    position: absolute;
    flex-direction: column;
    background-color: #fff;
    bottom: 40px;
    right: 0;
    border-radius: 16px;
    height: 85%;
    min-width: 375px;
    max-width: 390px;
    overflow: hidden;
  }

  .my__chat__modal__block {
    z-index: 9;
    display: flex;
    position: fixed;
    flex-direction: column;
    background: transparent;
    border-radius: 16px;
    bottom: 30px;
    right: 40px;
    min-width: 375px;
    max-width: 390px;
    overflow: hidden;
  }
  
  .chat__modal__header {
    border-bottom: 1px solid #ddd;
    box-shadow: 0 0 4px 0 rgb(0 0 0 / 10%);
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    border-radius: 16px 16px 0 0;
    position: relative;
    overflow: hidden;
  }
  
  .chat__modal__header__logo {
    position: relative;
    margin-right: 1rem;
    margin-left: 25px;
    width: 45px;
    height: 45px;
  }
  
  .chat__modal__header__logo img {
    width: 100%;
    height: 100%;
    border-radius: 999999px;
    object-fit: cover;
  }
  
  .chat__modal__header__heading {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
  
  .chat__modal__header__heading .title {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    margin-left: 0.8rem;
  }
  
  .chat__modal__header__heading .description {
    margin-right: 2px;
    border-radius: 999999px;
    width: 10px;
    height: 10px;
    background-color: #77ce04;
    position: relative;
    display: inline-block;
    z-index: 1;
  }
  
  .chat__modal__header__heading .description::before {
    content: "";
    margin-right: 2px;
    border-radius: 999999px;
    width: 100%;
    height: 100%;
    background-color: #77ce04;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
    z-index: -1;
  }
  
  .-red .description::before {
    background-color: #ce0430 !important;
  }
  
  .-red .description {
    background-color: #ce0430 !important;
  }
  
  .chat__modal__header__question {
    position: absolute;
    right: -25px;
  }
  
  .chat__modal__body {
    flex-grow: 1;
    background-color: #edf0f5;
    overflow: hidden;
    overflow-y: auto;
    padding: 25px;
  }
  
  .chat__modal__body::-webkit-scrollbar {
    width: 6px;
    border-radius: 9px;
    position: relative;
    right: 10px;
  }
  
  .chat__modal__body::-webkit-scrollbar-track {
    border: none;
  }
  
  .chat__modal__body::-webkit-scrollbar-thumb {
    background-color: #ced2db;
    outline: none;
    border-radius: 9px;
    position: relative;
    right: 10px;
  }
  
  textarea::-webkit-scrollbar {
    width: 6px;
    border-radius: 9px;
    position: relative;
    right: 10px;
  }
  
  textarea::-webkit-scrollbar-track {
    border: none;
  }
  
  textarea::-webkit-scrollbar-thumb {
    background-color: #ced2db;
    outline: none;
    border-radius: 9px;
    position: relative;
    right: 10px;
  }
  
  .poweredBy__question {
    position: absolute;
    right: -50px;
    bottom: -40px;
  }
  
  .poweredBy__question img {
    width: 169px;
    height: 169px;
    object-fit: cover;
  }
  
  .poweredBy {
    background-color: #edf0f5;
    width: 100%;
    padding-bottom: 27px;
    color: #b9b9b9;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
  }
  
  .poweredBy__text {
    margin-left: 25px;
    margin-right: 25px;
    width: 100%;
    padding-top: 17px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-top: 1px solid #e6e6e6;
    font-size: 13px;
  }
  
  .corner-radius {
    border-radius: 0 0 16px 16px;
  }
  
  .chat__modal__body .message {
    font-size: 14px;
    line-height: 1.4;
    pointer-events: auto;
    zoom: 1;
    resize: none;
    -webkit-tap-highlight-color: transparent;
    margin-bottom: 15px;
    max-width: 90%;
    display: flex;
  }
  
  .chat__modal__body .message.operator {
    margin-right: auto;
  }
  
  .operator .user_style {
    margin-right: 0.5rem;
  }
  
  .client .user_style {
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
  
  .message.operator .content {
    color: #000;
    background-color: #ffffff;
    box-shadow: 0 0 6px 3px rgb(0 0 0 / 9%);
    border-top-left-radius: 0 !important;
  }
  
  .message.client .content {
    color: #000;
    background-color: rgba(111, 162, 77, 0.15) !important;
    border-top-right-radius: 0 !important;
  }
  
  .content {
    font-size: 13px;
    line-height: 1.4;
    pointer-events: auto;
    zoom: 1;
    resize: none;
    -webkit-tap-highlight-color: transparent;
    border-radius: 16px;
    padding: 10px 15px;
    position: relative !important;
  }
  
  .text {
    word-wrap: break-word;
    word-break: break-word;
    margin-top: 0.5rem;
    font-weight: 500;
    font-size: 13px;
  }
  
  .operator .text {
    color: #000000;
  }
  
  .client .text {
    color: #000000;
  }
  
  .sender .time {
    font-size: 11px;
    font-weight: 400;
    margin-left: auto;
  }
  
  .operator .sender .time {
    font-size: 11px;
    font-weight: 400;
    margin-left: auto;
    color: #000;
  }
  
  .sender {
    display: flex;
    margin-top: 2px;
  }
  
  .client .sender .time {
    color: #000;
  }
  
  .chat__modal__body .message.client {
    margin-left: auto;
    justify-content: flex-end;
  }
  
  .chat__modal__footer {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    border-top: 1px solid #ddd;
    padding: 10px 25px;
    border-radius: 0 0 16px 16px;
  }
  
  .chat__modal__footer form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  
  .chat__modal__footer textarea {
    padding: 5px;
    width: 95%;
    border: none;
    resize: none;
    box-sizing: border-box;
  }
  
  .chat__modal__footer textarea:focus {
    outline: none;
  }
  
  .chat__modal__footer .-input {
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  
  .chat__modal__footer .-footer {
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
  }
  
  .-footer .icon {
    padding-right: 10px;
    margin-right: auto;
    cursor: pointer;
  }
  
  .-footer .poweredBy {
    margin-left: auto;
    font-size: 12px;
  }
  
  .-input .send {
    margin-left: 6px;
    border: none;
    padding: 0;
    cursor: pointer;
    background-color: #6fa34e;
    border-radius: 6px;
    width: 29px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .send img {
    width: 75%;
    height: 60%;
  }
  
  .-red {
  }
  
  @media (max-width: 960px) {
    .chat__modal__block {
      width: 50%;
    }
    .my__chat__modal__block {
      width: 50%;
    }
  }
  
  @media (max-width: 640px) {
    .chat__modal__block {
      width: calc(100% - 40px);
      max-width: calc(100% - 40px);
      right: 0;
      bottom: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: calc(100% - 40px);
    }
    .my__chat__modal__block {
      width: calc(100% - 40px);
      max-width: calc(100% - 40px);
      right: 0;
      bottom: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: calc(100% - 40px);
    }
  }
  
  @keyframes ping {
    75%,
    to {
      opacity: 0;
      transform: scale(2);
    }
  }
  
  .animate-ping {
    -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }
  </style>
  
