<template>
  <div :class="content === true ? ' border-b border-[rgba(255,255,255,0.11)]' : 'bg-white'">
    <div class="grid-container mx-2">
      <div class="uk-visible@m flex justify-between h-[102px]">
        <div class="flex self-center" uk-scrollspy="cls: uk-animation-slide-left-medium; delay:300; repeat: true">
          <router-link to="/">
            <img v-if="content === false" class="h-[60px] w-[180px] mr-[30px]" src="@/assets/images/icons/logo-real.svg" alt="">
            <img v-if="content === true" class="h-[60px] w-[180px] mr-[30px]" src="@/assets/images/icons/logo-white.svg" alt="">
          </router-link>
          <ul :class="['flex xl:space-x-4 lg:space-x-2 md:space-x-1 self-center font-normal text-sm max-lg:text-xs bottom-line', content === true ? 'text-white' : 'text-[#112827]' ]">
            <li>
              <router-link to="/" :class="['hover:no-underline', content === true ? 'hover:text-white' : 'hover:text-[#112827]']" href="javascript:void(0)">
                {{ $t('pages.pageName1') }}
              </router-link>
            </li>
            <li>
              <router-link to="/umrah" :class="['hover:no-underline', content === true ? 'hover:text-white' : 'hover:text-[#112827]' ]" href="javascript:void(0)">
                {{ $t('pages.pageName2') }}
              </router-link>
            </li>
            <li>
              <router-link to="/about" :class="['hover:no-underline', content === true ? 'hover:text-white' : 'hover:text-[#112827]' ]" href="javascript:void(0)">
                {{ $t('pages.pageName3') }}
              </router-link>
            </li>
            <li>
              <router-link to="/plansPage" :class="['hover:no-underline', content === true ? 'hover:text-white' : 'hover:text-[#112827]' ]" href="javascript:void(0)">
                {{ $t('pages.pageName4') }}
              </router-link>
            </li>
            <li>
              <router-link to="/news" :class="['hover:no-underline', content === true ? 'hover:text-white' : 'hover:text-[#112827]' ]" href="javascript:void(0)">
                {{ $t('pages.pageName5') }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="self-center flex space-x-4" uk-scrollspy="cls: uk-animation-slide-right-medium; repeat: true">
          <div class="flex self-center cursor-pointer space-x-1 mr-[30px] max-xl:mr-[10px] max-lg:[0px]">
            <a v-if="!content" href="javascript:void(0)"
               class="w-[37px] h-[37px] flex space-x-1 items-center justify-center text-[#0C0C0C] text-[16px] font-bold hover:no-underline hover:text-[#0C0C0C]">
              <div class="flex flex-row space-x-2 max-xl:space-x-1">
                <img class="ml-2" v-if="$t('language') === 'O’z' || $t('language') === 'Ўз'" src="@/assets/images/icons/navbar/uzbek.svg" alt="">
                <img v-if="$t('language') === 'En'" class="w-[26px] h-[16px] rounded-sm self-center ml-2" src="@/assets/images/icons/navbar/united-kingdom.svg" alt="">
                <img v-if="$t('language') === 'Ру'" class="w-[26px] h-[16px] rounded-sm self-center ml-2" src="@/assets/images/icons/navbar/russia.svg" alt="">
                                 <span
                                     class="w-[37px] h-[37px] flex items-center justify-center text-[16px] font-medium">
                                    {{ $t('language')}}
                                   <svg class="ml-1" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 1L5 5L9 1" stroke="#0C0C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                                </span>
              </div>

              <div uk-dropdown="mode: click;pos: bottom-right"
                   class="rounded-[5px] bg-[#fff] uk-box-shadow-medium dark-lang"
                   style="padding: 5px!important; min-width: 47px!important;">
                <ul class="uk-navbar-dropdown-nav text-[#000]">
                  <li
                      v-for="(item,index) in languages"
                      :key="index"
                      class="first:mt-0 mt-2 px-6 pt-1 border-b">
                    <a href="javascript:void(0)"
                       class="mySelectOption hover:no-underline navbar-lang dark-lang font-medium uk-dropdown-close font-bold text-[16px]"
                       @click="setLang(item)"
                    >
                      {{ item === 'uz' ? 'O’zbek' : '' }}
                      {{ item === 'en' ? 'English' : '' }}
                      {{ item === 'ru' ? 'Русский' : '' }}
                      {{ item === 'uk' ? 'Ўзбек' : '' }}
                    </a>
                  </li>
                </ul>
              </div>
            </a>
            <a v-if="content" href="javascript:void(0)"
               class="w-[37px] h-[37px] flex space-x-1 items-center justify-center text-white text-[16px] font-bold hover:no-underline hover:text-white">
                                <span
                                    class="w-[37px] h-[37px] flex items-center justify-center text-[16px] font-medium">
                                    {{ $t('language')}}
                                   <svg class="ml-1" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 1L5 5L9 1" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                                </span>
              <div uk-dropdown="mode: click;pos: bottom-right"
                   class="rounded-[5px] bg-[#fff] uk-box-shadow-medium dark-lang"
                   style="padding: 5px!important; min-width: 47px!important;">
                <ul class="uk-navbar-dropdown-nav text-[#000]">
                  <li
                      v-for="(item,index) in languages"
                      :key="index"
                      class="first:mt-0 mt-2 px-6 pt-1 border-b">
                    <a href="javascript:void(0)"
                       class="mySelectOptionDark hover:no-underline navbar-lang dark-lang font-medium uk-dropdown-close font-bold text-[16px]"
                       @click="setLang(item)"
                    >
                      {{ item === 'uz' ? 'O’zbek' : '' }}
                      {{ item === 'en' ? 'English' : '' }}
                      {{ item === 'ru' ? 'Русский' : '' }}
                      {{ item === 'uk' ? 'Ўзбек' : '' }}
                    </a>
                  </li>
                </ul>
              </div>
            </a>
          </div>
          <a :class="['flex space-x-5 py-[17px] px-[24px] rounded-[27px] cursor-pointer hover:no-underline', content === true ? 'bg-white' : 'bg-[#2D6D62]']" href="tel:+998 95 001 44 44">
            <img v-if="content === false" class="w-[20px] h-[20px] self-center" src="@/assets/images/icons/phone-call.svg" alt="">
            <img v-if="content === true" class="w-[20px] h-[20px] self-center" src="@/assets/images/icons/green-phone-call.svg" alt="">
            <div :class="['font-semibold lg:text-lg md:text-sm text-sm self-center leading-[16px] max-lg:leading-[8px]', content === true ? 'text-[#18494B] hover:text-[#18494B]' : 'text-white']">
              +998 95 001 44 44
            </div>
          </a>
        </div>
      </div>

      <div id="sidenav" uk-offcanvas="flip: true" class="uk-offcanvas">
        <div class="uk-offcanvas-bar">
          <div class="border-b border-[#3B8578] pt-4 pb-6">
            <router-link to="/">
              <img class="h-[40px] w-[177.43px] mr-[70px]" src="@/assets/images/icons/logo-white.svg" alt="">
            </router-link>
            <svg class="uk-offcanvas-close top-[5%] right-[5%] self-center" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_331_5762)">
                <path d="M24 1.414L22.586 0L12 10.586L1.414 0L0 1.414L10.586 12L0 22.586L1.414 24L12 13.414L22.586 24L24 22.586L13.414 12L24 1.414Z" fill="white"/>
              </g>
              <defs>
                <clipPath id="clip0_331_5762">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </div>
          <ul class="flex-col space-y-6 mt-8 self-center font-normal text-[#112827] text-base bottom-line">
            <li>
              <router-link to="/" :class="['hover:no-underline', content === true ? 'hover:text-white' : 'hover:text-[#112827]']" href="javascript:void(0)">
                {{ $t('pages.pageName1') }}
              </router-link>
            </li>
            <li>
              <router-link to="/umrah" :class="['hover:no-underline', content === true ? 'hover:text-white' : 'hover:text-[#112827]' ]" href="javascript:void(0)">
                {{ $t('pages.pageName2') }}
              </router-link>
            </li>
            <li>
              <router-link to="about" :class="['hover:no-underline', content === true ? 'hover:text-white' : 'hover:text-[#112827]' ]" href="javascript:void(0)">
                {{ $t('pages.pageName3') }}
              </router-link>
            </li>
            <li>
              <router-link to="plansPage" :class="['hover:no-underline', content === true ? 'hover:text-white' : 'hover:text-[#112827]' ]" href="javascript:void(0)">
                {{ $t('pages.pageName4') }}
              </router-link>
            </li>
            <li>
              <router-link to="news" :class="['hover:no-underline', content === true ? 'hover:text-white' : 'hover:text-[#112827]' ]" href="javascript:void(0)">
                {{ $t('pages.pageName5') }}
              </router-link>
            </li>
          </ul>
          <div class="flex self-center cursor-pointer space-x-1 mr-[30px] max-xl:mr-[0px] mt-4">
            <a href="javascript:void(0)"
               class="w-[110px] h-[37px] flex space-x-1 items-center text-[#0C0C0C] text-[16px] font-bold hover:no-underline hover:text-[#0C0C0C]">
              <div class="flex flex-row space-x-2">
                <img v-if="$t('language') === 'O’z' || $t('language') === 'Ўз'" src="@/assets/images/icons/navbar/uzbek.svg" alt="">
                <img v-if="$t('language') === 'En'" class="w-[26px] h-[16px] rounded-sm self-center" src="@/assets/images/icons/navbar/united-kingdom.svg" alt="">
                <img v-if="$t('language') === 'Ру'" class="w-[26px] h-[16px] rounded-sm self-center" src="@/assets/images/icons/navbar/russia.svg" alt="">
                  <span
                      class="w-[100px] h-[37px] flex items-center text-[16px] font-medium">
                                   <p v-if="$t('language') === 'O’z'">O’zbekcha</p>
                                   <p v-if="$t('language') === 'En'">English</p>
                                   <p v-if="$t('language') === 'Ру'">Русский</p>
                                   <p v-if="$t('language') === 'Ўз'">Ўзбекча</p>
                                   <svg class="ml-1" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 1L5 5L9 1" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                                </span>
              </div>
              <div uk-dropdown="mode: click;pos: bottom-right"
                   class="rounded-[5px] bg-[#fff] uk-box-shadow-medium dark-lang"
                   style="padding: 5px!important; min-width: 47px!important;">
                <ul class=" uk-navbar-dropdown-nav text-[#000]">
                  <li
                      v-for="(item,index) in languages"
                      :key="index"
                      class="first:mt-0 mt-2 px-6 pt-1 border-b">
                    <a href="javascript:void(0)"
                       class="myLittleSelectOption hover:no-underline navbar-lang dark-lang font-medium uk-dropdown-close font-bold text-[16px]"
                       @click="setLang(item)"
                    >
                      {{ item === 'uz' ? 'O’zbek' : '' }}
                      {{ item === 'en' ? 'English' : '' }}
                      {{ item === 'ru' ? 'Русский' : '' }}
                      {{ item === 'uk' ? 'Ўзбек' : '' }}
                    </a>
                  </li>
                </ul>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="uk-navbar-left uk-hidden@m">
          <router-link to="/">
            <img v-if="content === false" class="h-[50px] w-[160px] mr-[70px]" src="@/assets/images/icons/logo-real.svg" alt="">
            <img v-if="content === true" class="h-[50px] w-[160px] mr-[70px]" src="@/assets/images/icons/logo-white.svg" alt="">
          </router-link>
        </div>
        <div class="uk-navbar-right uk-hidden@m">
          <a href="#" class="uk-navbar-toggle" uk-navbar-toggle-icon uk-toggle="target: #sidenav"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useI18n } from 'vue-i18n'
const { t, locale} = useI18n({useScope: 'global'})
const setLang = (value) => {
  locale.value = value;
  localStorage.setItem('lang', locale.value);

  window.dispatchEvent(new CustomEvent('lang-changed',{
    detail: {
      storage: localStorage.getItem('lang')
    }
  }))
  // window.location.reload();

}
</script>
<script>
export default {
  name: "Navbar",
  data: function() {
    return {
      languages: ['uz', 'en', 'ru', 'uk'],
    }
  },
  methods: {
    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
  },
  props: {
    content: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
.bottom-line li a {
  position: relative;
  padding-bottom: 6px;
}

.bottom-line li:hover a::after {
  content: '';
  background-color: #E0B580;
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 0;
  transition: .5s ease;
}

.uk-offcanvas-bar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -270px;
  box-sizing: border-box;
  width: 270px;
  padding: 20px 20px;
  background: #2D6D62 !important;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.uk-navbar-toggle {
  padding: 0 0;
  color: #112827!important;
}
.mySelectOption:hover {
  color: #E0B580!important;
}
.mySelectOption {
  color: #112827!important;
}
.myLittleSelectOption {
  color: #2D6D62!important;
}
.myLittleSelectOption:hover {
  color: #E0B580!important;
}
.mySelectOptionDark {
  color: #2D6D62!important;
}
.mySelectOptionDark:hover {
  color: #E0B580!important;
}

</style>
