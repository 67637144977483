<template>
<div>
    <Navbar/>
    <BuyHeader/>
    <p class="grid-container font-bold text-2xl text-[#070303] my-10 max-lg:text-xl max-md:text-lg" uk-scrollspy="cls: uk-animation-slide-left-medium; delay:300; repeat: false">
        {{ $t('payment.text') }}</p>
    <div class="flex flex-col space-y-4 mb-[227px] max-lg:mb-[200px] max-md:mb-[180px] max-sm:mb-[140px]">
        <AboutCustomer/>
        <PaymentType/>
        <CardInfo/>
    </div>
    <PaymentNavigation/>
</div>
</template>

<script>
import BuyHeader from "@/components/Pages/BuyInfo/BuyInfoComp/BuyHeader";
import Navbar from "@/components/Layouts/Navbar";
import AboutCustomer from "@/components/Pages/Payment/PaymentComp/AboutCustomer";
import PaymentType from "@/components/Pages/Payment/PaymentComp/PaymentType";
import CardInfo from "@/components/Pages/Payment/PaymentComp/CardInfo";
import PaymentNavigation from "@/components/Pages/Payment/PaymentComp/PaymentNavigation";
export default {
    name: "PaymentComp",
    components: { PaymentNavigation, CardInfo, PaymentType, AboutCustomer, Navbar, BuyHeader }
}
</script>

<style scoped>

</style>