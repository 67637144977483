<template>
  <div>
    <div class="grid-container">
      <Heading class="md:mb-[61px] mb-4" uk-scrollspy="cls: uk-animation-slide-left-medium; delay:300; repeat: false">
        <template #menu> {{ $t('contacts.title') }} </template>
      </Heading>
      <div class="grid lg:grid-cols-2 grid-cols-1 md:gap-x-[70px]">
        <iframe class="lg:flex lg:w-[535px] lg:h-[507px] md:w-[450px] md:h-[422px] w-full h-[325px] md:mb-0 mb-6" uk-scrollspy="cls: uk-animation-slide-left-medium; delay:300; repeat: false" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d4389.484880143!2d69.24274597576364!3d41.315277557370976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2s8JHF868V%2B468!5e1!3m2!1sen!2s!4v1677647451327!5m2!1sen!2s" width="535" height="507" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        <div class="self-center" uk-scrollspy="cls: uk-animation-slide-right-medium; delay:300; repeat: false">
          <div class="font-semibold md:text-4xl text-2xl text-[#112827] md:leading-[54px] md:mb-[32px] mb-8">
            {{ $t('contacts.addressTitle') }}
          </div>
          <div class="grid lg:grid-cols-1 md:grid-cols-2 gap-x-8">
            <div>
              <div class="flex md:mb-7 mb-4">
                <div class="font-bold md:text-2xl text-xl text-[#2D3134] mr-5 self-center">
                  {{ $t('contacts.target') }}:
                </div>
                <div class="font-normal md:text-xl md:text-base text-sm text-[#2D3134] self-center">
                  {{ $t('contacts.target1') }}
                </div>
              </div>
              <div class="font-bold md:text-2xl text-xl text-[#2D3134] md:leading-[29px] mb-[16px]">
                {{ $t('contacts.time') }}:
              </div>
              <div class="font-normal md:text-xl text-base text-[#2D3134] mb-8">
                <span class="block">{{ $t('contacts.time1') }} 9:00 - 18:00</span>
              </div>
            </div>
            <div>
              <div class="font-bold md:text-2xl text-xl text-[#2D3134] leading-[29px] mb-4">
                {{ $t('contacts.number') }}:
              </div>
              <div class="font-normal md:text-xl text-base text-[#2D3134] mb-8">
                <div class="flex space-x-2 mb-2">
                  <div class="font-medium text-xl text-[#2D3134] mr-4">
                    {{ $t('contacts.name1') }}:
                  </div>
                  <a href="tel:+998 95 001 44 44" class="hover:no-underline block hover:text-[#2D3134]">
                    +998 95 001 44 44
                  </a>
                </div>
                <!-- <div class="flex space-x-2 mb-2">
                  <div class="font-medium text-xl text-[#2D3134] mr-4">
                    {{ $t('contacts.name2') }}:
                  </div>
                  <a href="tel:+998 99 973 66 66" class="hover:no-underline block hover:text-[#2D3134]">
                    +998 99 973 66 66
                  </a>
                </div> -->
                <div class="flex space-x-2 mb-2">
                  <a href="http://t.me/Ayupov_O" class="self-center hover:no-underline hover:text-[#2D3134]">
                    <!-- <img class="w-[32px] h-[32px] self-center" src="@/assets/images/icons/telegram.svg" alt=""> -->
                    <div class="font-medium text-xl text-[#2D3134] mr-4">
                      {{ $t('contacts.social') }}:
                    </div>
                  </a>
                  <a href="http://t.me/Ayupov_O" class="hover:no-underline block hover:text-[#2D3134] self-center">+998 90 940 30 77</a>
                </div>
              </div>
              <div class="md:flex md:space-x-4 mb-6">
                <div class="font-bold md:text-2xl text-xl text-[#2D3134] leading-[29px] mb-[24px]">
                  {{ $t('contacts.socials') }}:
                </div>
                <div class="flex space-x-3.5">
                  <a href="https://www.facebook.com/tanwiruz" class="cursor-pointer" target="_blank">
                    <img class="w-[32px] h-[32px]" src="@/assets/images/icons/facebook.svg" alt="">
                  </a>
                  <a href="https://www.instagram.com/tanwir.uz/" target="_blank" class="cursor-pointer">
                    <img class="w-[32px] h-[32px]" src="@/assets/images/icons/instagram.svg" alt="">
                  </a>
                  <a href="https://t.me/tanwiruz" target="_blank" class="cursor-pointer">
                    <img class="w-[32px] h-[32px]" src="@/assets/images/icons/telegram-diagram.svg" alt="">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Heading from '@/components/Blocks/Heading';

export default {
  name: 'Contacts',
  components: {
    Heading,
  }
}
</script>

<style>

</style>