<template>
<div :class="content ? 'bg-[#112827]' : 'md:bg-white bg-[#F4F7F6]'">
    <div class="grid-container lg:py-[72px] md:py-[40px] py-[35px] relative z-10">
        <div class="lg:flex md:flex md:flex-wrap justify-between">
            <div class="md:mb-0 mb-8" uk-scrollspy="cls: uk-animation-slide-bottom-medium; delay:300; repeat: false">
                <router-link to="/" class="">
                    <img v-if="content === false" class="h-[60px] w-[180px] mr-[70px]" src="@/assets/images/icons/logo-real.svg" alt="">
                    <img v-if="content === true" class="h-[60px] w-[180px] mr-[70px]" src="@/assets/images/icons/logo-white.svg" alt="">
                </router-link>
                <div class="flex space-x-6 lg:mt-[40px] mt-5">
                    <a href="https://www.facebook.com/tanwiruz" class="cursor-pointer" target="_blank">
                        <img v-if="content === false" class="w-[32px] h-[32px]" src="@/assets/images/icons/facebook.svg" alt="">

                        <svg v-if="content === true" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="16" cy="16" r="15.5" stroke="white" />
                            <path d="M16.8946 22V16.7982H18.6636L18.9284 14.576H16.8945V13.1573C16.8945 12.514 17.0597 12.0755 17.9124 12.0755L19 12.075V10.0876C18.8119 10.0605 18.1662 10 17.4152 10C15.8471 10 14.7735 11.0355 14.7735 12.9373V14.576H13V16.7982H14.7735V21.9999L16.8946 22Z" fill="white" />
                        </svg>
                    </a>
                    <a href="https://www.instagram.com/tanwir.uz/" target="_blank" class="cursor-pointer">
                        <img v-if="content === false" class="w-[32px] h-[32px]" src="@/assets/images/icons/instagram.svg" alt="">

                        <svg v-if="content === true" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="16" cy="16" r="15.5" stroke="white" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.07 10.315C12.4512 10.1667 12.8877 10.0654 13.5265 10.0363C14.1666 10.0068 14.3712 10 16.0007 10C17.6302 10 17.8347 10.0072 18.4745 10.0363C19.1128 10.0649 19.549 10.1667 19.9309 10.315C20.325 10.4682 20.6599 10.6732 20.9933 11.0071C21.3268 11.341 21.5319 11.6754 21.6855 12.07C21.8338 12.4511 21.9351 12.8875 21.9641 13.5263C21.9932 14.1661 22 14.3706 22 16C22 17.6294 21.9932 17.8335 21.9641 18.4737C21.9355 19.1124 21.8338 19.5487 21.6855 19.9301C21.5319 20.3245 21.3273 20.6595 20.9933 20.9929C20.6594 21.3263 20.325 21.5314 19.9305 21.685C19.549 21.8333 19.1128 21.9346 18.474 21.9637C17.8343 21.9932 17.6297 22 16.0003 22C14.3708 22 14.1666 21.9927 13.5265 21.9637C12.8877 21.9346 12.4519 21.8333 12.07 21.685C11.6753 21.5314 11.341 21.3262 11.0071 20.9929C10.6732 20.6597 10.4682 20.3245 10.315 19.9301C10.1667 19.5487 10.0654 19.1125 10.0363 18.4737C10.0068 17.8339 10 17.6294 10 16C10 14.3706 10.0068 14.1661 10.0363 13.5263C10.0654 12.8876 10.1667 12.4514 10.315 12.07C10.4682 11.6752 10.6732 11.3405 11.0071 11.0071C11.3411 10.6737 11.6753 10.4682 12.07 10.315ZM15.9997 14C14.8953 14 14 14.8955 14 16C14 17.1045 14.8953 18 15.9997 18C17.1043 18 18 17.1043 18 16C18 14.8957 17.1043 14 15.9997 14ZM12.6667 16C12.6667 14.1594 14.1586 12.6667 15.9997 12.6667C17.8405 12.6667 19.3333 14.1591 19.3333 16C19.3333 17.8409 17.8405 19.3333 15.9997 19.3333C14.1586 19.3333 12.6667 17.8407 12.6667 16ZM20 12.6667C20.3682 12.6667 20.6667 12.3682 20.6667 12C20.6667 11.6318 20.3682 11.3333 20 11.3333C19.6318 11.3333 19.3333 11.6318 19.3333 12C19.3333 12.3682 19.6318 12.6667 20 12.6667Z" fill="white" />
                        </svg>
                    </a>
                    <a href="https://t.me/tanwiruz" target="_blank" class="cursor-pointer">
                        <img v-if="content === false" class="w-[32px] h-[32px]" src="@/assets/images/icons/telegram-diagram.svg" alt="">

                        <svg v-if="content ===true" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="16" cy="16" r="15.5" stroke="white" />
                            <path d="M9.85832 15.226C9.85832 15.226 16.0502 12.5918 18.1976 11.6642C19.0208 11.2932 21.8125 10.1059 21.8125 10.1059C21.8125 10.1059 23.101 9.58653 22.9936 10.848C22.9578 11.3674 22.6715 13.1854 22.3852 15.1518C21.9557 17.9345 21.4904 20.9769 21.4904 20.9769C21.4904 20.9769 21.4188 21.8302 20.8104 21.9786C20.202 22.127 19.1998 21.4592 19.0208 21.3108C18.8776 21.1995 16.3365 19.5299 15.4059 18.7136C15.1554 18.491 14.8691 18.0458 15.4417 17.5264C16.7302 16.302 18.2692 14.7808 19.1998 13.8162C19.6293 13.3709 20.0587 12.3321 18.2692 13.5935C15.7281 15.4116 13.2227 17.1183 13.2227 17.1183C13.2227 17.1183 12.65 17.4893 11.5763 17.1553C10.5025 16.8215 9.24983 16.3762 9.24983 16.3762C9.24983 16.3762 8.39089 15.8197 9.85832 15.226Z" fill="white" />
                        </svg>
                    </a>

                </div>
            </div>
<!--          We can delete form here to comment if we want to use footer items with data-->
                      <div class="uk-grid grid-cols-4 md:gap-x-32 md:text-start text-center mt-6 " uk-scrollspy="cls: uk-animation-slide-bottom-medium;">
                        <div>
                          <div class="font-medium text-lg leading-[18px] mb-[22px]">{{ $t('footer.title1.title') }}</div>
                          <ul class="flex-col space-y-2">
                            <li>{{ $t('footer.title1.text') }}</li>
                            <li>{{ $t('footer.title1.text1') }}</li>
                            <li>{{ $t('footer.title1.text2') }}</li>
                          </ul>
                        </div>
                        <div>
                          <div class="font-medium text-lg leading-[18px] mb-[22px]">{{ $t('footer.title2.title') }}</div>
                          <ul class="flex-col space-y-2">
                            <li>App Store</li>
                            <li>Google Play Store</li>
                          </ul>
                        </div>
                        <form @submit.prevent="saveFooterForm">
                          <div :class="['font-medium text-lg leading-[18px] mb-[22px]', content ? 'text-white' : 'text-[#2D3134]']">
                            {{ $t('footer.title3.title') }}!
                          </div>
                          <div class="mb-4">
                            <input
                                v-model="phone_number"
                                required
                                placeholder="+998 (__) ___ __ __"
                                class="mt-1 px-3 py-2 bg-white border border-[#ECECEC] relative w-[245px] min-h-[56px] md:min-h-[56px]
                                        transition duration-150 placeholder-[#112827] placeholder:font-normal focus:outline-none
                                        focus:border-[#ECECEC] focus:ring-[#F2F2F2] block rounded-2xl md:text-[18px] focus:ring-1 font-medium"
                                type="tel" v-maska:[options] data-maska-eager
                            >
                          </div>
                          <div>
                            <button type="submit" class="font-medium text-sm text-white py-[12px] px-[17px] bg-[#E0B580] rounded-xl flex justofy-start">
                              {{ $t('footer.title3.button') }}
                            </button>
                          </div>
                        </form>
                      </div>
<!--            <div class="uk-grid grid-cols-4 md:gap-x-32 md:text-start text-center mt-8" uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: .footer_card; delay:300; repeat: false">-->
<!--                <div v-for="(l, index) in links" :key="index" class="footer_card">-->
<!--                    <div :class="['font-medium text-lg leading-[18px] mb-[22px]', content ? 'text-white' : 'text-[#2D3134]']">-->
<!--                        {{ l.head }}-->
<!--                    </div>-->
<!--                    <ul :class="['font-normal text-sm leading-[34px]', content === true ? 'text-white' : 'text-[#676A6C]']">-->
<!--                        <li v-for="(m, index) in l.menu" :key="index">-->
<!--                            <router-link :to="m.url">{{ m.name }} </router-link>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--                <div class="footer_card">-->
<!--                    <form @submit.prevent="saveFooterForm">-->
<!--                        <div :class="['font-medium text-lg leading-[18px] mb-[22px]', content ? 'text-white' : 'text-[#2D3134]']">-->
<!--                          {{ $t('footer.title3.title') }}!-->
<!--                        </div>-->
<!--                        <div class="mb-4">-->
<!--                            <input-->
<!--                                v-model="phone_number"-->
<!--                                required-->
<!--                                placeholder="+998 (__) ___ __ __"-->
<!--                                class="mt-1 px-3 py-2 bg-white border border-[#ECECEC] relative w-[245px] min-h-[56px] md:min-h-[56px]-->
<!--                                        transition duration-150 placeholder-[#112827] placeholder:font-normal focus:outline-none-->
<!--                                        focus:border-[#ECECEC] focus:ring-[#F2F2F2] block rounded-2xl md:text-[18px] focus:ring-1 font-medium"-->
<!--                                         type="tel" v-maska:[options] data-maska-eager-->
<!--                            >-->
<!--                        </div>-->
<!--                        <div>-->
<!--                            <button type="submit" class="font-medium text-sm text-white py-[12px] px-[17px] bg-[#E0B580] rounded-xl flex justofy-start">-->
<!--                              {{ $t('footer.title3.button') }}-->
<!--                            </button>-->
<!--                        </div>-->
<!--                    </form>-->
<!--                </div>-->
<!--            </div>-->
        </div>
        <div v-if="show" class="md:w-[350px] absolute right-0 top-2 p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800" role="alert">
            <span class="font-medium text-sm">{{ $t('mobile.alert') }}!</span>
         </div>
        <div v-if="errorAlert" class="md:w-[350px] absolute right-0 top-2 p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
            <span class="font-medium text-lg">{{ $t('mobile.alert1') }}!</span>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import { vMaska } from "maska"

export default {
    name: "Footer",
    props: {
        content: {
            type: Boolean,
            default: false,
        }
    },
    directives: { maska: vMaska },
    data: () => ({
        links: [
            // {
            //     id: 1,
            //     head: 'Tanwir',
            //     menu: [{
            //             id: 1,
            //             name: 'Home',
            //             url: 'javascript:void(0)',
            //         },
            //         {
            //             id: 2,
            //             name: 'Blog',
            //             url: 'javascript:void(0)',
            //         },
            //         {
            //             id: 3,
            //             name: 'Plans',
            //             url: 'javascript:void(0)',
            //         },
            //         {
            //             id: 4,
            //             name: 'News',
            //             url: 'javascript:void(0)',
            //         },
            //     ]
            // },
            {
                id: 2,
                head: 'Компания',
                menu: [{
                        id: 1,
                        name: 'Биз ҳақимизда',
                        url: 'about',
                    },
                    {
                        id: 2,
                        name: 'Умра тарифлари',
                        url: 'plansPage',
                    },
                    {
                        id: 3,
                        name: 'Янгиликлар',
                        url: 'news',
                    },
                ]
            },
            {
                id: 3,
                head: 'Илова',
                menu: [{
                        id: 1,
                        name: 'App Store',
                        url: '/',
                    },
                    {
                        id: 2,
                        name: 'Google Play Store',
                        url: '/',
                    },
                ]
            },
        ],
        show: false,
        errorAlert: false,
        phone_number: '+998',
        options: {
            mask: "+### (##) ### ## ##",
            eager: true,
            onMaska: (detail) => console.log(detail.completed)
        }
    }),
    methods: {
        saveFooterForm() {
            const requests = {
                full_name: "Without name",
                phone_number: this.phone_number,
                trip_request_statuses_id: 0,
                description: '',
            }
            if(this.phone_number === '+998 (' || this.phone_number.length < 19) {
                this.errorAlert = true
                setInterval(() => {
                this.errorAlert = false
                }, 4000)
                return 0;
            } else {
                axios
                    .post('https://tanwir.uz:6443/main/trip-requests/', requests)
                        .then(response => {
                            this.show = true
                            setInterval(() => {
                                this.show = false
                            }, 5000)
                        })
                        .catch((err) => {
                            this.errorAlert = true
                            setInterval(() => {
                                this.errorAlert = false
                            }, 4000)
                        })
            }
            this.phone_number = '+998 '
        },
    },
}
</script>

<style>
ul li a:hover {
    text-decoration: none;
    color: #676A6C;
}

.footer_card {
    margin-bottom: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
