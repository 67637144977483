<template>
  <div>
    <NavbarVue />
    <div class="bg-[#EEF4F3] py-[40px] mb-[72px]">
      <div class="grid-container" uk-scrollspy="cls: uk-animation-slide-left-medium; delay:300; repeat: false">
        <div>
          <h1 class="font-bold md:text-4xl text-2xl text-[#070303] md:leading-[54px] mb-8">
            {{ $t('about.section.title') }}
          </h1>
        </div>
        <div>
          <p class="font-normal md:text-base text-sm text-[#070303] md:mr-[15rem] mr-0 leading-[26px]">
            {{ $t('about.section.text') }}.
          </p><br>
          <p class="font-normal md:text-base text-sm text-[#070303] md:mr-[15rem] mr-0 leading-[26px]">
            {{ $t('about.section.text1') }}:
          </p>
          <ul class="font-normal md:text-base text-sm text-[#070303] md:mr-[15rem] ml-6 leading-[26px]">
            <li>
              - {{ $t('about.section.text2') }};
            </li>
            <li>
              - {{ $t('about.section.text3') }};
            </li>
            <li>
              - {{ $t('about.section.text4') }};
            </li>
            <li>
              - {{ $t('about.section.text5') }};
            </li>
            <li>
              - {{ $t('about.section.text6') }};
            </li>
          </ul>
          <p class="font-normal md:text-base text-sm text-[#070303] md:mr-[15rem] mr-0 leading-[26px]">
            {{ $t('about.section.text7') }}.
          </p><br>
          <p class="font-normal md:text-base text-sm text-[#070303] md:mr-[15rem] mr-0 leading-[26px]">
            {{ $t('about.section.text8') }}.
          </p>
        </div>
      </div>
    </div>
    <div class="grid-container md:mb-[96px] mb-16">
      <div class="md:mb-[96px] mb-16" uk-scrollspy="cls: uk-animation-slide-bottom-medium; delay:300; repeat: false">
        <img class="w-full md:h-[423px] h-[380px] rounded-[20px] object-cover" src="@/assets/images/tanwir_kaba.png" alt="">
      </div>
      <div class="md:mb-[120px] mb-[60px]">
        <div class="md:flex md:space-x-[49px]">
          <div class="text-center md:text-start" uk-scrollspy="cls: uk-animation-slide-left-medium; delay:300; repeat: false">
            <div class="font-semibold md:text-4xl text-2xl text-[#E0B580] mb-4">
              {{ $t('about.section1.title') }}
            </div>
            <div align="justify" class="font-normal md:text-base text-sm text-[#5B5F62] mb-6">
              {{ $t('about.section1.text') }}.
            </div>
            <!-- <div class="font-normal md:text-base text-sm text-[#5B5F62] text-center">
                Биз хизматларимиз энг юқори ишонч ва умидликка лойиқ бўлишини таъминлашга интиламиз.
            </div> -->
            <br />
            <div align="justify" class="font-normal md:text-base text-sm text-[#5B5F62]">
              {{ $t('about.section1.text1') }}.
            </div>
          </div>
          <div uk-scrollspy="cls: uk-animation-slide-right-medium; delay:300; repeat: false">
            <div class="grid grid-rows-2 grid-flow-col gap-[30px] w-[680px] uk-visible@m">
              <img class="w-[270px] h-[250px] rounded-[20px] object-cover" src="@/assets/images/book.png" alt="" />
              <img class="w-[270px] h-[250px] rounded-[20px] object-cover" src="@/assets/images/praying_people.png" alt="" />
              <img class="row-span-2 w-[370px] h-[526px] rounded-[20px] object-cover" src="@/assets/images/mosque.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="md:flex md:space-x-[46px]">
          <img src="@/assets/images/smile_woman_c.jpg" class="w-[565px] h-[431px] rounded-[20px] object-cover uk-visible@m" uk-scrollspy="cls: uk-animation-slide-left-medium; delay:300; repeat: false" alt="">
          <div class="text-center md:text-start" uk-scrollspy="cls: uk-animation-slide-right-medium; delay:300; repeat: false">
            <div class="font-semibold md:text-4xl text-2xl text-[#E0B580] md:leading-[54px] mb-4">
              {{ $t('about.section2.title') }}
            </div>
            <div class="font-normal md:text-base text-sm text-[#5B5F62]">
              {{ $t('about.section2.text') }}.
            </div>
            <br />
            <div class="font-normal md:text-base text-sm text-[#5B5F62]">
              {{ $t('about.section2.text1') }}.
            </div>
          </div>
        </div>
      </div>
    </div>
    <ContactsVue/>
    <FooterVue />
  </div>
</template>

<script>
import NavbarVue from '../Layouts/Navbar.vue';
import FooterVue from '../Layouts/Footer.vue';
import ContactsVue from '../Layouts/Contacts.vue';
export default {
  name: "AboutUs",
  components: {
    NavbarVue,
    FooterVue,
    ContactsVue,
  }
}
</script>

<style lang="">

</style>
