<template>
  <div>
    <div
        class="rounded-2xl bg-white shadow-[0_24px_90px_rgba(183,183,183,0.22)] p-[12px] pb-[24px] hover:no-underline"
        :class="[page === true ? 'mb-0 mt-0' : 'mb:mb-[147px] mb-[60px] mt-[70px]']"
    >
      <img
          class="w-full h-[252px] rounded-2xl object-cover mb-[24px]"
          :src="img"
          alt=""
      />
      <div class="h-[175px] grid content-between">
        <div class="font-semibold text-2xl text-[#2D3134] leading-[24px] mb-[14px]">
          {{ name }}
        </div>
        <div class="font-normal text-sm text-[#5B5F62] leading-[20px] mb-[9px] max-word-d">
          {{ title }}
        </div>
        <div class="flex justify-end items-end">
          <router-link :to="`/newscontent/${id}`"
                       class="font-normal text-sm leading-[14px] text-white py-[12px] px-[33px] rounded-[46px] bg-[#2D3134] cursor-pointer hover:no-underline hover:text-white"
          >
            {{ $t('newsCard.text') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsCard",
  props: {
    id: {
      type: String,
      default: ''
    },
    img: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    page: {
      type: Boolean,
      default: false,
    }
  },

};
</script>

<style>
.max-word-d {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
