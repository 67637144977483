<template>
    <div>
        <div>
            <ChatButton @closeButton="openChatButton" v-if="chatButton"/>
        </div>
        <div class="chat_button relative" v-if="!chatButton && (routeName !== `plantype` && routeName !== `BuyInfo` && routeName !== `Payment`)">
            <img class="hover:cursor-pointer" @click="openChatButton" src="@/assets/chat_button.svg" alt="">
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
import { useRoute } from 'vue-router';
import {computed} from "vue";
import ChatButton from '@/components/Layouts/ChatButton.vue';
import {Authorization} from "@/services/Authorization";

import UIkit from "uikit/dist/js/uikit.min";
import Icons from "uikit/dist/js/uikit-icons";

UIkit.use(Icons);
export default {
    name: "App",
    components: { ChatButton },
    data () {
        return {
            login: 'tanwir',
            password: 'tanwir',
            chatButton: false,
        }
    },
    methods: {
        openChatButton () {
            this.chatButton = !this.chatButton
        },
        Authorization : async function () {
            let formData = new FormData();
            formData.append('login', this.login)
            formData.append('password', this.password)
            try {
                let response = await Authorization.login(formData);
                if(response) {
                    localStorage.setItem('myToken', response.data.token)
                }
            }
            catch (error) {
                console.log(error.response.data.code_description);
            }
        }
    },
    created () {
            this.Authorization()
    },
    setup () {
        const routeName = computed(() => {
            return useRoute().name
        })
        return { routeName }
    }
};
</script>

<style>
@import url("~uikit/dist/css/uikit.min.css");
@import url("./assets/styles/output.css");
@import url("./assets/styles/main.css");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
    font-family: "Inter", sans-serif !important;
}
</style>
