<template>
<div class="bg-[#F7FAF9]">
    <div class="grid-container flex flex-col space-y-4 py-10 max-lg:py-8 max-md:py-6 text-[#070303] max-lg:space-y-3 max-md:space-y-2" uk-scrollspy="cls: uk-animation-slide-left-medium; delay:300; repeat: false">
        <p class="font-bold text-4xl max-lg:text-3xl max-md:text-2xl max-sm:text-xl">{{ $t("buyInfo.header.text") }}</p>
        <p class="font-normal text-base w-[38%] max-lg:w-[50%] max-md:w-[70%] max-sm:w-[60%] max-md:text-sm max-sm:text-xs">{{ $t("buyInfo.header.text1") }}</p>
    </div>
</div>
</template>

<script>
export default {
    name: "BuyHeader"
}
</script>

<style scoped>

</style>