import en from './en.json'
import uz from './uz.json'
import ru from './ru.json'
import uk from './uk.json'

export const defaultLocale = 'uk'

export const languages = {
    en,
    uz,
    ru,
    uk
}