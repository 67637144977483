<template>
  <div class="md:mb-[80px] mb-[40px]">
    <div class="grid-container">
      <Heading class="md:mb-[70px] mb-6" uk-scrollspy="cls: uk-animation-slide-left-medium; delay:300; repeat: false">
        <template #menu>
          {{ $t('partners') }}
        </template>
        <template #title>
        </template>
      </Heading>
      <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slider="autoplay:true; autoplay-interval: 6000; pause-on-hover: false">

        <ul class="uk-slider-items uk-child-width-1-2 uk-child-width-1-4@m uk-grid" uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: .card_uk; delay:300; repeat: false">
          <li class="flex">
            <div class="uk-panel self-center">
              <img class="card_uk" src="@/assets/images/icons/partners/uzbekistan_airways.svg" width="400" height="600" alt="">
            </div>
          </li>
          <li class="flex">
            <div class="uk-panel self-center">
              <img class="card_uk" src="@/assets/images/icons/partners/qanot_sharq.svg" width="400" height="600" alt="">
            </div>
          </li>
          <li class="flex">
            <div class="uk-panel self-center">
              <img class="card_uk" src="@/assets/images/icons/partners/jazeera.svg" width="400" height="600" alt="">
            </div>
          </li>
          <li class="flex">
            <div class="uk-panel self-center">
              <img class="card_uk" src="@/assets/images/icons/partners/flynas.svg" width="400" height="600" alt="">
            </div>
          </li>
          <li class="flex">
            <div class="uk-panel self-center">
              <img class="card_uk" src="@/assets/images/icons/partners/azerbaijan.svg" width="400" height="600" alt="">
            </div>
          </li>
        </ul>

      </div>
    </div>
  </div>
</template>

<script>
import Heading from '@/components/Blocks/Heading';
export default {
  name: "Partners",
  components: {
    Heading
  }
}
</script>

<style>

</style>