<template>
  <div class="lg:mb-[120px] md:mb-[60px] sm:mb-12 mb-8">
    <div class="grid-container">
      <Heading
          class="md:mb-[70px] mb-[30px]"
          uk-scrollspy="cls: uk-animation-slide-left-medium; delay:300; repeat: false"
      >
        <template #menu> {{ $t('choose.title')}}? </template>
        <template #title>
          {{ $t('choose.text')}}.
        </template>
      </Heading>
      <div class="flex lg:flex-row md:flex-row sm:flex-col flex-col md:space-x-[49px]">
        <div class="text-center md:text-start order-2" uk-scrollspy="cls: uk-animation-slide-left-medium; delay:300; repeat: false">
          <!-- <div class="font-semibold md:text-4xl text-2xl text-[#112827] md:leading-[54px] mb-[40px]">
            Years of persistent service, success, and acclamation
          </div> -->
          <div align="justify" class="font-normal md:text-base text-sm text-[#5B5F62] mt-4">
            {{ $t('choose.text1')}}.
          </div> <br/>
          <div align="justify" class="font-normal md:text-base text-sm text-[#5B5F62]">
            {{ $t('choose.text2')}}.
          </div>
          <br>
          <div align="justify" class="font-normal md:text-base text-sm text-[#5B5F62]">
            {{ $t('choose.text3')}}.
          </div>
        </div>
        <div class="md:order-2 order-1" uk-scrollspy="cls: uk-animation-slide-right-medium; delay:300; repeat: false">
          <div class="grid grid-rows-2 grid-flow-col lg:gap-[30px] md:gap-4 sm:gap-4 gap-3 lg:w-[680px] md:w-[500px] sm:w-[400px] w-full">
            <img
                class="lg:w-[270px] md:w-full w-[138px] lg:h-[250px] md:h-fuh-full rounded-[20px] object-cover"
                src="@/assets/images/tanwirenterence.jpg"
                alt=""
            />
            <img
                class="lg:w-[270px] md:w-full w-[138px] lg:h-[250px] md:h-fuh-full object-cover"
                src="@/assets/images/tanwirinside.jpg"
                alt=""
            />
            <img
                class="row-span-2 lg:w-[370px] md:w-[320px] sm:w-[189px] w-[189px] lg:h-[526px] h-full rounded-[20px] object-cover"
                src="@/assets/images/tanwirlogo.jpg"
                alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Heading from "@/components/Blocks/Heading";
export default {
  name: "WhyChoose",
  components: {
    Heading,
  },
};
</script>

<style></style>
