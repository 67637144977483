<template>
  <div>
    <NavbarVue/>
    <div class="bg-[#EEF4F3] py-[40px] mb-[40px]">
      <div class="grid-container">
        <div>
          <h1 class="font-bold md:text-4xl text-2xl text-[#070303] md:leading-[54px] mb-4">
            {{ $t('newsPage.title') }}
          </h1>
        </div>
        <div>
          <p class="font-normal text-base text-[#070303] md:mr-[43rem] mr-0">
            {{ $t('newsPage.text') }}
          </p>
        </div>
      </div>
    </div>
    <div class="grid-container">
      <div v-if="!loading" class="grid md:grid-cols-3 grid-cols-1 gap-[30px]">
        <div v-for="(u , index) in newsTable" :key="index">
          <router-link :to="`/newscontent/${u.id}`" class="hover:no-underline">
            <NewsCard :img="getImage(u.cover_img_files_id, u.cover_img_files_uid)" :name="u.title" :title=" u.subtitle" :id="u.id" :page="page"/>
          </router-link>
        </div>
      </div>
      <div v-if="loading" class="grid md:grid-cols-3 grid-cols-1 gap-[30.5px]">
        <div v-for="index in 18" :key="index">
          <Skeleton/>
        </div>
      </div>
    </div>
    <PaginationNews v-show="lastPage" @myEvent="myPage" :myLastPage='lastPage' :myCurrentPage='eventPage'/>
    <FooterVue/>
  </div>
</template>
<script>
import NavbarVue from '../Layouts/Navbar.vue';
import FooterVue from '../Layouts/Footer.vue';
import NewsCard from "@/components/Blocks/NewsCard";

import news_a from '@/assets/images/news_a.png'
import news_b from '@/assets/images/news_b.png'
import news_c from '@/assets/images/news_c.png'
import axios from 'axios';
import PaginationNews from "@/components/Pagination/PaginationNews";
import Skeleton from "@/components/Loader/Skeleton";

export default {
  name: "News",
  components: {
    Skeleton,
    PaginationNews,
    NavbarVue,
    FooterVue,
    NewsCard,
  },
  data: function() {
    return{
      eventPage: 0,
      lastPage: 3,
      currentPage: 1,
      currentLang: 'uk',
      page: true,
      newsTable: [],
      loading: false,
    }
  },
  methods: {
    myPage(currentPage) {
      this.currentPage = currentPage
      this.check()
      this.init()
      if ( this.eventPage === 1 ) {
        this.eventPage = 0
      }
    },
    async init() {
      this.loading = true
     await axios
          .get('https://tanwir.uz:6443/gen/publications/view?page='+this.currentPage+'&rows_count=18&publication_lang='+ this.currentLang+'&status=true', {
            //   headers: {
            //     Authorization: 'Bearer ' + localStorage.getItem('token')
            //   }
          })
          .then(response => {
            this.newsTable = response.data.list
            this.loading = false
          })
          .catch((err) => {
            // console.log(err.response.status)
            //   if(err.response.status === 401) {
            //     router.push('/')
            //   }
            this.loading =false
          });
    },
    check() {
      axios
          .get('https://tanwir.uz:6443/gen/publications/view?page=1&rows_count=10000&publication_lang='+ this.currentLang+'&status=true', {
            // headers: {
            //   Authorization: 'Bearer ' + localStorage.getItem('token')
            // }
          })
          .then(response => {
            if(response.data.list.length%18 === 0){
              this.lastPage = response.data.list.length/18
            } else {
              this.lastPage =Math.floor(response.data.list.length/18)+1
            }
          })
          .catch((err) => {
            // console.log(err.response.status)
            //   if(err.response.status === 401) {
            //     router.push('/')
            //   }
          });
    },
    dateTime(value) {
      return moment(value).format("DD-MM-YYYY, h:mm:ss a");
    },
    getImage(id, uid) {
      return 'https://tanwir.uz:6443/gen/files/'+id+'/'+uid+'/view'
    }
  },
  watch: {
    currentLang() {
      this.check()
   this.eventPage = 1
    }
  },
  mounted() {
    window.addEventListener('lang-changed', (event) => {
      this.currentLang = event.detail.storage;
    });
    this.init()
  },
  created() {
    if(localStorage.getItem('lang'))
      this.currentLang = localStorage.getItem('lang')
    this.check()
  }
}
</script>
<style lang="">

</style>