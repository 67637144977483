<template>
  <div>
    <div class="lg:mb-[132px] md:mb-[53px] mb-6 z-10">
      <div class="grid-container bg-[#17B288] md:py-[29px] py-4 px-12 md:px-0 md:pl-[64px] sm:pt-6 pt-6 relative rounded-[54px]" uk-scrollspy="cls: uk-animation-slide-top-medium; delay:300; repeat: false">
        <div class="flex flex-wrap md:space-x-[80px]">
          <img class="md:w-[340px] md:h-[345px] sm:w-[250px] sm:h-[255px] object-cover" src="@/assets/images/women_with_hijab_and_headphone.png" alt="">
          <div class="self-center mb-8">
            <div class="font-bold md:text-[48px] sm:text-4xl text-[32px] text-white md:mb-8 mb-4
                            md:text-start text-center md:leading-[72px]">
              {{ $t('mobile.title') }}?
            </div>
            <div>
              <div class="font-semibold md:text-xl text-lg text-white leading-[28px] mb-8 md:text-start text-center">
                {{ $t('mobile.text1') }}  <br class="uk-visible@m"> {{ $t('mobile.text2') }}
              </div>
            </div>
            <div class="">
              <form @submit.prevent="saveFooterForm" class="flex flex-wrap md:space-x-3 md:justify-start justify-center">
                <div class="">
                  <input
                      v-model="phone_number"
                      placeholder="+998 (__) ___ __ __"
                      class="px-3 py-2 bg-white border border-[#ECECEC] relative w-[245px] min-h-[56px] md:min-h-[56px]
                                            transition duration-150 placeholder-[#112827] placeholder:font-normal focus:outline-none 
                                            focus:border-[#ECECEC] focus:ring-[#F2F2F2]  block rounded-2xl md:text-[18px] focus:ring-1 font-medium"
                      type="tel" v-maska:[options] data-maska-eager
                  >
                </div>
                <div>
                  <button type="submit" class="font-medium text-sm text-white py-[18px] px-[51px] bg-[#E0B580] rounded-xl flex justofy-start md:mt-0 mt-2">
                    {{ $t('mobile.button') }}
                  </button>
                </div>
              </form>
              <div v-if="show" class="md:w-[350px] absolute right-0 top-2 p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800" role="alert">
                <span class="font-medium text-sm">{{ $t('mobile.alert') }}!</span>
              </div>
              <div v-if="errorAlert" class="md:w-[350px] absolute right-0 top-2 p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
                <span class="font-medium text-lg">{{ $t('mobile.alert1') }}!</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="bg-[#18494B] md:mb-[53px] mb-6 bg__image md:pt-0 sm:pt-6 pt-6">
        <div class="grid-container relative" uk-scrollspy="cls: uk-animation-slide-top-medium; delay:300; repeat: false">
            <div class="grid md:grid-cols-2 grid-cols-1 justify-items-start">
                <div class="self-center md:mr-[100px] mr-0 mb-8">
                    <div class="font-semibold md:text-[56px] sm:text-4xl text-[32px] text-white md:mb-8 mb-4 md:leading-[60px] leading-[48px] 
                            md:text-start text-center">
                        Бизнинг мобил иловамиз
                    </div>
                    <div class="font-semibold md:text-[32px] text-xl text-white leading-[28px] mb-[34px] md:text-start text-center">
                        Тез орада!
                    </div>
                    <div class="flex space-x-3 md:justify-start justify-center">
                        <img src="@/assets/images/icons/appstore.svg" alt="">
                        <img src="@/assets/images/icons/googleplay.svg" alt="">
                    </div>
                </div>
                <div class="right-0 lg:w-[484px] md:w-[400px] lg:h-[484px] md:h-[400px] lg:right-36 md:right-0 -right-2 sm:w-[296px] sm:h-[296px] w-[296px] h-[296px] 
                            md:ml-[240px] sm:ml-[60px] ml-[30px] -z-1 absolute bg-white rounded-full md:mt-[50px] md:mb-[84px] sm:mb-9 mb-9 
                            bottom-0">
                </div>
                <div class="z-10 md:ml-4 ml-[90px]">
                    <img class="md:w-[425px] md:h-[618px] sm:w-[261px] sm:h-[353px] w-full h-full object-cover" src="@/assets/images/gold_phone.png" alt="">
                </div>
            </div>
        </div>
    </div> -->
  </div>
</template>

<script>
import axios from 'axios'
import { vMaska } from "maska"

export default {
  name: "MobileApp",
  data: () => ({
    show: false,
    errorAlert: false,
    phone_number: '+998',
    options: {
      mask: "+### (##) ### ## ##",
      eager: true,
      onMaska: (detail) => console.log(detail.completed)
    }
  }),
  methods: {
    saveFooterForm() {
      const requests = {
        full_name: "Without name",
        phone_number: this.phone_number,
        trip_request_statuses_id: 0,
        description: '',
      }
      if(this.phone_number === '+998 (' || this.phone_number.length < 19) {
        this.errorAlert = true
        setInterval(() => {
          this.errorAlert = false
        }, 4000)
        return 0;
      }
      else {
        axios
            .post('https://tanwir.uz:6443/main/trip-requests/', requests)
            .then(response => {
              this.show = true
              setInterval(() => {
                this.show = false
              }, 5000)
            })
            .catch((err) => {
              this.errorAlert = true
              setInterval(() => {
                this.errorAlert = false
              }, 4000)
            })
      }
      this.phone_number = '+998 '
    }
  },
}
</script>

<style>
.bg__image{
  /*background-image: url('@/assets/images/mobile_section_bg.png');
  background-image: url('@/assets/images/icons/sign-bg.svg');*/
  background-repeat: repeat;
}

@media (min-width:320px) and (max-width:374px){
  .white_bg{
    width: 240px;
    height: 240px;
  }
}
</style>