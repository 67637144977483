<template>
  <div>
    <Navbar/>
    <Header/>
    <Plans/>
    <WhyChoose/>
    <MobileApp/>
    <Partners/>
    <News/>
    <Contacts/>
    <Footer/>
  </div>
</template>

<script>
import Navbar from '@/components/Layouts/Navbar';
import Header from '@/components/Layouts/Header';
import Footer from '@/components/Layouts/Footer';
import MobileApp from '@/components/Layouts/MobileApp';
import Plans from '@/components/Layouts/Plans';
import WhyChoose from '@/components/Layouts/WhyChoose';
import Partners from '@/components/Layouts/Partners';
import News from '@/components/Layouts/News';
import Contacts from '@/components/Layouts/Contacts';
export default {
    data () {
      return {
        chatButton: false,
      }
    },
    name: "Index",
    components: {
        Navbar,
        Header,
        Footer,
        MobileApp,
        Plans,
        WhyChoose,
        Partners,
        News,
        Contacts,
    },
    methods: {
      openChatButton() {
        this.chatButton = !this.chatButton
      }
    }
}
</script>

<style>
</style>