<template>
    <div class="grid-container bg-[#F7FAF9] rounded-2xl flex flex-col space-y-6 px-8 pt-6 pb-12">
        <div>
            <p class="text-xl text-[#070303] font-bold max-md:tex-lg max-sm:text-base" uk-scrollspy="cls: uk-animation-slide-left-medium; delay:300; repeat: false">
                {{ $t('payment.card.text') }}</p>
        </div>
        <div class="grid grid-cols-10 max-xl:grid-cols-9 max-lg:grid-cols-8 max-md:grid-cols-4 gap-y-6" uk-scrollspy="cls: uk-animation-slide-bottom-medium; delay:300; repeat: false">
            <div class="col-span-4 grid grid-cols-5 gap-x-8">
                <div class="col-span-3 flex flex-col justify-end">
                    <div class="flex space-x-3">
                        <label class="text-[#0C0C0C] text-base font-normal mb-2 max-md:text-sm max-sm:text-xs max-md:self-end" for=""> {{ $t('payment.card.text1') }}</label>
                        <img class="max-lg:w-8 max-lg:h-8 max-md:w-7 max-md:h-7 max-sm:w-6 max-sm:h-6 mb-1" src="@/assets/images/icons/payment/uzcard-mini.svg" alt="">
                    </div>
                    <input
                        class="p-4 max-xl:p-3 max-lg:p-2.5 max-md:p-2 max-sm:p-1.5 rounded-2xl border border-[#707974] border-solid placeholder:text-base max-md:placeholder:text-sm max-sm:placeholder:text-xs placeholder:font-normal placeholder:text-[#B9B9B9] focus:outline-none focus:border-[#5c6360] focus:ring-1 focus:ring-[#5c6360] bg-white"
                        type="text" placeholder="8600">
                </div>
                <div class="col-span-2 flex flex-col justify-end">
                    <label class="text-[#0C0C0C] text-base font-normal max-md:text-sm max-sm:text-xs" for=""> {{ $t('payment.card.text2') }}</label>
                    <input
                        class="p-4 max-xl:p-3 max-lg:p-2.5 max-md:p-2 max-sm:p-1.5 mt-2 rounded-2xl border border-[#707974] border-solid placeholder:text-base max-md:placeholder:text-sm max-sm:placeholder:text-xs placeholder:font-normal placeholder:text-[#B9B9B9] focus:outline-none focus:border-[#5c6360] focus:ring-1 focus:ring-[#5c6360] bg-white"
                        type="text" placeholder="__/__">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CardInfo"
}
</script>

<style scoped>

</style>