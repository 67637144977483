import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import VueMask from '@devindex/vue-mask'; 
import InputMask from 'vue-input-mask';
import VueMask from '@devindex/vue-mask'
// import Vue-i18n for Language
import { languages } from '@/i18n'
import {defaultLocale} from '@/i18n'
import {createI18n, useI18n} from 'vue-i18n'
// import Vue from 'vue';
import { vMaska } from "maska"
createApp({}).directive("maska", vMaska)

//For language
const localeStorageLang = localStorage.getItem('lang')
const messages = Object.assign(languages)
const i18n = createI18n({
    legacy: false,
    locale: localeStorageLang || defaultLocale,
    fallbackLocale: 'en',
    messages
})

createApp(App, { setup() {const {t} = useI18n()
        return {t}}}).use(i18n).directive("maska", vMaska).use(InputMask).use(VueMask).use(router).mount('#app')
// createApp(App).use(VueMask).use(router).mount('#app')

// Vue.component('input-mask', InputMask)
