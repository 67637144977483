<template>
    <div>
        <div class="grid-container flex flex-col my-10" uk-scrollspy="cls: uk-animation-slide-right-medium; delay:300; repeat: false">
            <div>
                <p class="mb-4 font-normal text-base max-md:text-sm text-[#070303] max-lg:mb-3 max-md:mb-2">{{ $t("buyInfo.info.text") }}</p>
            </div>
            <div class="flex space-x-1 text-base max-md:text-sm font-semibold mb-10 max-lg:mb-8 max-md:mb-6">
                <div class="flex">
                    <div
                        class="self-center flex space-x-1.5 px-5 py-2 max-lg:space-x-1 max-lg:px-2 max-lg:py-1.5 max-md:px-1.5 max-md:py-1 max-sm:px-1 max-sm:py-1 bg-[#E0B580] rounded-xl cursor-pointer text-white">
                        <img src="@/assets/images/icons/plans-type/1-person-white.svg" alt="">
                        <p>{{ $t("buyInfo.info.text1") }}</p>
                    </div>
                </div>
                <div class="flex">
                    <div
                        class="self-center flex space-x-1.5 px-5 py-2 max-lg:space-x-1 max-lg:px-2 max-lg:py-1.5 max-md:px-1.5 max-md:py-1 max-sm:px-1 max-sm:py-1 bg-white rounded-xl cursor-pointer text-[#112827] border border-[#ECECEC] rounded-2xl">
                        <img src="@/assets/images/icons/plans-type/2-persons.svg" alt="">
                        <p>{{ $t("buyInfo.info.text2") }}</p>
                    </div>
                </div>
                <div class="flex">
                    <div
                        class="self-center flex space-x-1.5 px-5 py-2 max-lg:space-x-1 max-lg:px-2 max-lg:py-1.5 max-md:px-1.5 max-md:py-1 max-sm:px-1 max-sm:py-1 bg-white rounded-xl cursor-pointer text-[#112827] border border-[#ECECEC] rounded-2xl">
                        <img src="@/assets/images/icons/plans-type/3-persons.svg" alt="">
                        <p>{{ $t("buyInfo.info.text3") }}</p>
                    </div>
                </div>
            </div>
            <div>
                <p class="text-2xl font-bold text-[#070303] max-md:text-xl max-sm:text-lg">{{ $t("buyInfo.info.text4") }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "InfoSection"
}
</script>

<style scoped>

</style>