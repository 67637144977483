<template>
    <div class="z-50">
        <div class=" fixed bottom-0 w-full bg-[#17B288] z-50">
            <div class="nav-container flex justify-between my-6 max-lg:flex-col max-lg:items-center max-lg:space-y-2 max-lg:my-2" uk-scrollspy="cls: uk-animation-slide-bottom-medium; delay:300; repeat: false">
                <div class="flex space-x-3 max-lg:space-x-2 max-md:space-x-1 text-base max-md:text-sm max-sm:text-xs font-semibold">
                    <div class="flex space-x-3">
                        <div class="self-center cursor-pointer">
                            <img class="max-md:w-8 max-md:h-8" src="@/assets/images/icons/payment/pray-man.svg" alt="" >
                        </div>
                        <div class="flex flex-col self-center font-semibold text-[#F7FAF9] max-lg:flex-row max-lg:space-x-1 max-sm:flex-col max-sm:space-x-0">
                            <p>{{ $t("buyInfo.navigation.text") }}</p>
                            <p>{{ $t("buyInfo.navigation.text1") }}</p>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="self-center">
                            <img src="@/assets/images/icons/payment/line.svg" alt="" >
                        </div>
                    </div>
                    <div class="flex space-x-3">
                        <div class="self-center cursor-pointer">
                            <img class="max-md:w-8 max-md:h-8" src="@/assets/images/icons/payment/payment-info.svg" alt="" >
                        </div>
                        <div class="flex flex-col self-center font-normal text-[#F7FAF9] max-lg:flex-row max-lg:space-x-1 max-sm:flex-col max-sm:space-x-0">
                            <p>{{ $t("buyInfo.navigation.text2") }}</p>
                            <p>{{ $t("buyInfo.navigation.text3") }}</p>
                        </div>
                    </div>
                </div>
                <div class="flex space-x-9 text-white max-lg:space-x-4 max-md:space-x-3">
                    <div class="text-center max-lg:flex max-lg:space-x-2 max-sm:flex-col">
                        <div class="self-center font-semibold text-2xl max-lg:text-xl max-md:text-lg max-sm:text-base">$ 1650</div>
                        <div class="self-center font-normal text-base max-md:text-sm max-sm:text-xs">(18 234 453 {{ $t('navigationBar.text3') }} )</div>
                    </div>
                    <div class="flex space-x-3 max-lg:space-x-2">
                        <router-link  to="/payment" class="self-center border border-white border-solid px-10 py-4 max-lg:px-8 max-lg:py-3 max-md:px-6 max-md:py-2 max-sm:py-1.5 max-sm:px-3 rounded-3xl hover:text-gray-300 hover:no-underline hover:border-gray-300 cursor-pointer">
                            <p class="font-medium max-sm:font-normal text-base max-md:text-sm hover:no-underline hover:text-gray-300">{{ $t("buyInfo.navigation.text4") }}</p>
                        </router-link>
                        <router-link to="/payment" class="self-center bg-[#112827] px-10 py-4 max-lg:px-8 max-lg:py-3 max-md:px-6 max-md:py-2 max-sm:py-1.5 max-sm:px-3 rounded-3xl hover:bg-[#0b2423] hover:text-gray-300 cursor-pointer hover:no-underline">
                            <p class="font-medium max-sm:font-normal text-base max-md:text-sm hover:no-underline hover:text-gray-300">{{ $t("navigationBar.text4") }}</p>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BuyNavigation",
    data: () => ({
        my_photo: false,
        my_photo1: false,
        my_photo2: false,
    })
}
</script>

<style scoped>
</style>