<template>
<div>
    <Navbar/>
    <BuyHeader/>
    <InfoSection/>
    <div class="mb-[227px] max-lg:mb-[200px] max-md:mb-[180px] max-sm:mb-[140px]">
        <CustomerForm/>
    </div>
        <BuyNavigation/>
</div>
</template>

<script>
import Navbar from "@/components/Layouts/Navbar";
import BuyHeader from "@/components/Pages/BuyInfo/BuyInfoComp/BuyHeader";
import InfoSection from "@/components/Pages/BuyInfo/BuyInfoComp/InfoSection";
import CustomerForm from "@/components/Pages/BuyInfo/BuyInfoComp/CustomerForm";
import BuyNavigation from "@/components/Pages/BuyInfo/BuyInfoComp/BuyNavigation";
export default {
    name: "BuyInfo",
    components: { BuyNavigation, CustomerForm, InfoSection, BuyHeader, Navbar }
}
</script>

<style scoped>

</style>