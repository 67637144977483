<template>
    <div class="grid-container bg-[#F7FAF9] rounded-2xl flex flex-col space-y-6 px-8 pt-6 pb-12 max-sm:px-12">
        <div>
            <p class="text-xl text-[#070303] font-bold max-md:tex-lg max-sm:text-base" uk-scrollspy="cls: uk-animation-slide-left-medium; delay:300; repeat: false">{{ $t("payment.about.text") }}</p>
        </div>
        <div class="grid grid-cols-4 gap-x-4 gap-y-6 max-lg:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1 max-lg:gap-x-3 max-lg:gap-y-5 max-md:gap-x-2 max-md:gap-y-4 max-sm:gap-x-1 max-sm:gap-y-3" uk-scrollspy="cls: uk-animation-slide-bottom-medium; delay:300; repeat: false">
            <div class="flex flex-col">
                <label class="text-[#0C0C0C] text-base font-normal max-md:text-sm max-sm:text-xs" for="">{{ $t("buyInfo.form.text") }}</label>
                <input
                    class="p-4 mt-2 max-xl:p-3 max-lg:p-2.5 max-md:p-2 max-sm:p-1.5 max-md:mt-1 rounded-2xl border border-[#707974] border-solid placeholder:text-base  max-md:placeholder:text-sm max-sm:placeholder:text-xs placeholder:font-normal placeholder:text-[#B9B9B9] focus:outline-none focus:border-[#5c6360] focus:ring-1 focus:ring-[#5c6360] bg-white"
                    type="text" :placeholder=" $t('buyInfo.form.text')">
            </div>
            <div class="flex flex-col">
                <label class="text-[#0C0C0C] text-base font-normal max-md:text-sm max-sm:text-xs" for="">{{ $t("buyInfo.form.text1") }}</label>
                <input
                    class="p-4 mt-2 max-xl:p-3 max-lg:p-2.5 max-md:p-2 max-sm:p-1.5 max-md:mt-1 rounded-2xl border border-[#707974] border-solid placeholder:text-base  max-md:placeholder:text-sm max-sm:placeholder:text-xs placeholder:font-normal placeholder:text-[#B9B9B9] focus:outline-none focus:border-[#5c6360] focus:ring-1 focus:ring-[#5c6360] bg-white"
                    type="text" :placeholder="$t('buyInfo.form.text1')">
            </div>
            <div class="flex flex-col">
                <label class="text-[#0C0C0C] text-base font-normal max-md:text-sm max-sm:text-xs" for="">{{ $t('payment.about.text1') }}</label>
                <input
                    class="p-4 mt-2 max-xl:p-3 max-lg:p-2.5 max-md:p-2 max-sm:p-1.5 max-md:mt-1 rounded-2xl border border-[#707974] border-solid placeholder:text-base  max-md:placeholder:text-sm max-sm:placeholder:text-xs placeholder:font-normal placeholder:text-[#B9B9B9] focus:outline-none focus:border-[#5c6360] focus:ring-1 focus:ring-[#5c6360] bg-white"
                    type="number" :placeholder="$t('payment.about.text1')">
            </div>
            <div class="flex flex-col">
                <label class="text-[#0C0C0C] text-base font-normal max-md:text-sm max-sm:text-xs" for="">{{ $t('payment.about.text2') }}</label>
                <input
                    class="p-4 mt-2 max-xl:p-3 max-lg:p-2.5 max-md:p-2 max-sm:p-1.5 max-md:mt-1 rounded-2xl border border-[#707974] border-solid placeholder:text-base  max-md:placeholder:text-sm max-sm:placeholder:text-xs placeholder:font-normal placeholder:text-[#B9B9B9] focus:outline-none focus:border-[#5c6360] focus:ring-1 focus:ring-[#5c6360] bg-white"
                    type="text" :placeholder="$t('payment.about.text2')">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AboutCustomer"
}
</script>

<style scoped>

</style>