<template>
    <div class="grid-container bg-[#F7FAF9] rounded-2xl flex flex-col space-y-6 px-8 pt-6 pb-20 max-sm:px-12">
        <div>
            <p class="text-xl text-[#070303] font-bold max-md:text-lg max-sm:text-base" uk-scrollspy="cls: uk-animation-slide-left-medium; delay:300; repeat: false">{{ $t("buyInfo.form.title") }}</p>
        </div>
        <div class="grid grid-cols-4 gap-x-4 gap-y-6 max-lg:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1 max-lg:gap-x-3 max-lg:gap-y-5 max-md:gap-x-2 max-md:gap-y-4 max-sm:gap-x-1 max-sm:gap-y-3" uk-scrollspy="cls: uk-animation-slide-bottom-medium; delay:300; repeat: false">
            <div class="flex flex-col">
                <label class="text-[#0C0C0C] text-base font-normal max-md:text-sm max-sm:text-xs" for="">{{ $t("buyInfo.form.text") }}</label>
                <input
                    class="p-4 mt-2 max-xl:p-3 max-lg:p-2.5 max-md:p-2 max-sm:p-1.5 max-md:mt-1 rounded-2xl border border-[#707974] border-solid placeholder:text-base max-md:placeholder:text-sm max-sm:placeholder:text-xs placeholder:font-normal placeholder:text-[#B9B9B9] focus:outline-none focus:border-[#5c6360] focus:ring-1 focus:ring-[#5c6360] bg-white"
                    type="text" :placeholder="$t('buyInfo.form.text')">
            </div>
            <div class="flex flex-col">
                <label class="text-[#0C0C0C] text-base font-normal max-md:text-sm max-sm:text-xs" for="">{{ $t("buyInfo.form.text1") }}</label>
                <input
                    class="p-4 mt-2 max-xl:p-3 max-lg:p-2.5 max-md:p-2 max-sm:p-1.5 max-md:mt-1 rounded-2xl border border-[#707974] border-solid placeholder:text-base max-md:placeholder:text-sm max-sm:placeholder:text-xs placeholder:font-normal placeholder:text-[#B9B9B9] focus:outline-none focus:border-[#5c6360] focus:ring-1 focus:ring-[#5c6360] bg-white"
                    type="text" :placeholder="$t('buyInfo.form.text1')">
            </div>
            <div class="flex flex-col">
                <label class="text-[#0C0C0C] text-base font-normal max-md:text-sm max-sm:text-xs" for="">{{ $t("buyInfo.form.text2") }}</label>
                <input
                    class="p-4 mt-2 max-xl:p-3 max-lg:p-2.5 max-md:p-2 max-sm:p-1.5 max-md:mt-1 rounded-2xl border border-[#707974] border-solid placeholder:text-base max-md:placeholder:text-sm max-sm:placeholder:text-xs placeholder:font-normal placeholder:text-[#B9B9B9] focus:outline-none focus:border-[#5c6360] focus:ring-1 focus:ring-[#5c6360] bg-white"
                    type="text" :placeholder="$t('buyInfo.form.text2')">
            </div>
            <div class="flex flex-col">
                <label class="text-[#0C0C0C] text-base font-normal max-md:text-sm max-sm:text-xs" for="">{{ $t("buyInfo.form.text3") }}</label>
                <input
                    class="p-4 mt-2 max-xl:p-3 max-lg:p-2.5 max-md:p-2 max-sm:p-1.5 max-md:mt-1 rounded-2xl border border-[#707974] border-solid placeholder:text-base max-md:placeholder:text-sm max-sm:placeholder:text-xs placeholder:font-normal placeholder:text-[#B9B9B9] focus:outline-none focus:border-[#5c6360] focus:ring-1 focus:ring-[#5c6360] bg-white"
                    type="text" placeholder="dd/mm/yy">
            </div>
            <div class="flex flex-col order-last max-md:flex-row max-md:space-x-2 max-sm:flex-col max-sm:space-x-0">
                <p class="text-[#0C0C0C] text-base font-normal max-md:text-sm max-sm:text-xs">{{ $t("buyInfo.form.text4") }}</p>
                <div class="flex space-x-10 mt-5 max-xl:space-x-8 max-lg:space-x-8 max-md-space-x-4 max-sm-space-x-2 max-md:mt-1 max-sm:mt-3 max-md:flex-col max-md:space-x-0 max-md:space-y-1 max-sm:flex-row max-sm:space-x-4 max-sm:space-y-0">
                    <div class="flex space-x-4 max-lg:space-x-3 max-md:space-x-2">
                        <img src="@/assets/images/icons/payment/male.svg" alt="">
                        <label class="self-center text-[#0C0C0C] text-base font-medium max-md:text-sm max-sm:text-xs cursor-pointer" for="male">{{ $t("buyInfo.form.input") }}</label>
                        <input id="male" type="radio" name="gender" value="m" class="ml-1 cursor-pointer">
                    </div>
                    <div class="flex space-x-3 max-lg:space-x-3 max-md:space-x-2">
                        <img src="@/assets/images/icons/payment/female.svg" alt="">
                        <label class="self-center text-[#0C0C0C] text-base font-medium max-md:text-sm max-sm:text-xs cursor-pointer" for="female">{{ $t("buyInfo.form.input1") }}</label>
                        <input id="female" type="radio" name="gender" value="f" class="ml-1 cursor-pointer">
                    </div>
                </div>
            </div>
            <div class="flex flex-col">
                <label class="text-[#0C0C0C] text-base font-normal max-md:text-sm max-sm:text-xs" for="">{{ $t("buyInfo.form.text5") }}</label>
                <input
                    class="p-4 mt-2 max-xl:p-3 max-lg:p-2.5 max-md:p-2 max-sm:p-1.5 max-md:mt-1 rounded-2xl border border-[#707974] border-solid placeholder:text-base max-md:placeholder:text-sm max-sm:placeholder:text-xs placeholder:font-normal placeholder:text-[#B9B9B9] focus:outline-none focus:border-[#5c6360] focus:ring-1 focus:ring-[#5c6360] bg-white"
                    type="text" :placeholder="$t('buyInfo.form.text5')">
            </div>
            <div class="flex flex-col">
                <label class="text-[#0C0C0C] text-base font-normal max-md:text-sm max-sm:text-xs truncate" for="">{{ $t("buyInfo.form.text6") }}</label>
                <input
                    class="p-4 mt-2 max-xl:p-3 max-lg:p-2.5 max-md:p-2 max-sm:p-1.5 max-md:mt-1 rounded-2xl border border-[#707974] border-solid placeholder:text-base max-md:placeholder:text-sm max-sm:placeholder:text-xs placeholder:font-normal placeholder:text-[#B9B9B9] focus:outline-none focus:border-[#5c6360] focus:ring-1 focus:ring-[#5c6360] bg-white truncate"
                    type="text" :placeholder="$t('buyInfo.form.text6')">
            </div>
            <div class="flex flex-col">
                <label class="text-[#0C0C0C] text-base font-normal max-md:text-sm max-sm:text-xs truncate" for="">{{ $t("buyInfo.form.text7") }}</label>
                <input
                    class="p-4 mt-2 max-xl:p-3 max-lg:p-2.5 max-md:p-2 max-sm:p-1.5 max-md:mt-1 rounded-2xl border border-[#707974] border-solid placeholder:text-base max-md:placeholder:text-sm max-sm:placeholder:text-xs placeholder:font-normal placeholder:text-[#B9B9B9] focus:outline-none focus:border-[#5c6360] focus:ring-1 focus:ring-[#5c6360] bg-white truncate"
                    type="text" :placeholder="$t('buyInfo.form.text7')">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CustomerForm"
}
</script>

<style scoped>
input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: 5px;
    left: -1px;
    position: relative;
    background-color: white;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #85938E;
}

input[type='radio']:checked:after {
    width: 20px;
    height: 20px;
    border-radius: 15px;
    top: 6px;
    left: -4px;
    position: relative;
    background-image: url("@/assets/images/icons/payment/radio-input.svg");
    content: '';
    display: inline-block;
    visibility: visible;
    border: 0 solid #17B288;
}
</style>