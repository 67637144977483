import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '@/components/Home/Index.vue';
import AboutUs from '@/components/Pages/AboutUs.vue';
import News from '@/components/Pages/News.vue';
import NewsContent from '@/components/Pages/NewsContent.vue';
import BuyInfo from "@/components/Pages/BuyInfo/BuyInfo.vue";
import Payment from "@/components/Pages/Payment/PaymentComp.vue"
const routes = [
  {
    path: '/', name: 'home', component: Home,
  },
  {
    path: '/about', name: 'about', component: AboutUs,
  },
  {
    path: '/news', name: 'news', component: News,
  },
  {
    path: '/newscontent/:formId', name: 'newscontent', component: NewsContent,
  },
  {
    path: '/buy-info', name: 'BuyInfo', component: BuyInfo,
  },
  {
    path: '/payment', name: 'Payment', component: Payment,
  },
  {
    path: '/plansPage', 
    name: 'plansPage', 
    component: () => import('@/components/Pages/PlansPage.vue')
  },
  {
    path: '/plantype', 
    name: 'plantype', 
    component: () => import('@/components/Pages/PlanTypePage.vue')
  },
  {
    path: '/umrah', 
    name: 'umrah', 
    component: () => import('@/components/Pages/Umrah.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(), // history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
