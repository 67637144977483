<template>
<div>
    <NavbarVue :content="contents" class="bg-[#2D6D62]"/>
    <div class="bg-[#2D6D62] py-[40px] mb-[40px]">
        <div class="grid-container" uk-scrollspy="cls: uk-animation-slide-left-medium; delay:300; repeat: true">
            <div>
                <h1 class="font-bold md:text-4xl text-2xl text-white md:leading-[54px] mb-4">
                    {{ title }}
                </h1>
            </div>
            <div>
                <p class="font-normal text-base text-white md:mr-[43rem] mr-0">
                    {{ subtitle }}
                </p>
            </div>
        </div>
    </div>
    <div class="grid-container">
        <div class="md:w-[942px] mx-auto">
            <img class="w-full h-full rounded-2xl mb-[20px]" :src="getImage(cover_img_files_id, cover_img_files_uid)" alt="" uk-scrollspy="cls: uk-animation-slide-bottom-medium; delay:300; repeat: true">
            <div class="">
                <div class="flex mb-[20px]">
                    <div class="flex space-x-4 self-center" uk-scrollspy="cls: uk-animation-slide-left-medium; delay:300; repeat: true">
                        <img src="@/assets/images/icons/fi-bs-calendar.svg" alt="">
                        <span class="font-semibold text-[#B9B9B9] text-base">{{ dateTime(publication_date) }}</span>
                    </div>
                  <div class="flex space-x-4 ml-6 self-center" uk-scrollspy="cls: uk-animation-slide-left-medium; delay:300; repeat: true">
                    <span class="font-semibold text-[#B9B9B9] text-base">Foto: {{ publication_source }}</span>
                  </div>
                </div>
                <div uk-scrollspy="cls: uk-animation-slide-left-medium; delay:300; repeat: true">
                    <h1 class="font-extrabold text-2xl text-[#112827] mb-8">
                      {{ subtitle }}
                    </h1>
                </div>
                <div uk-scrollspy="cls: uk-animation-slide-left-medium; delay:300; repeat: true">
                    <p class="font-normal md:text-base text-sm text-[#5B5F62]">
                        <span v-html="content"></span>    
                    </p>
                </div>
            </div>
        </div>
    </div>
    <FooterVue />
</div>
</template>

<script>
import NavbarVue from '../Layouts/Navbar.vue';
import FooterVue from '../Layouts/Footer.vue';
import NewsCard from "@/components/Blocks/NewsCard";
import axios from 'axios';
import moment from "moment";

export default {
    name: "NewsContent",
    components: {
        NavbarVue,
        FooterVue,
        NewsCard,
    },
    data: () => ({
        page: true,
        contents: true,

        id: '',
        title: '',
        subtitle: '',
        content: '',
        cover_img_files_id: 0,
        cover_img_files_uid: 0,
        status: false,
        publication_date: '',
        publication_lang: 'uz',
        publication_source: '',
    }),
    methods: {
        async getNews() {
            await axios
                  .get('https://tanwir.uz:6443/gen/publications/' + this.formId, {
                    //   headers: {
                    //       Authorization: 'Bearer ' + localStorage.getItem('token')
                    //   }
                  })
                  .then(response => {
                      this.id = response.data.id
                      this.title = response.data.title
                      this.subtitle = response.data.subtitle
                      this.content = response.data.content
                      this.cover_img_files_id = response.data.cover_img_files_id
                      this.cover_img_files_uid = response.data.cover_img_files_uid
                      this.status = response.data.status
                      this.publication_date = response.data.publication_date
                      this.publication_lang = response.data.publication_lang
                      this.publication_source = response.data.publication_source
                    console.log(response.data)
                  })
                  .catch((err) => console.log(err.response));
        },
        dateTime(value) {
            return moment(value).format("DD.MM.YYYY");
        },
        getImage(id, uid) {
            return 'https://tanwir.uz:6443/gen/files/'+id+'/'+uid+'/view'
        }
    },
    created() {
          let formId = this.$route.params.formId;
          this.formId = formId;
          console.log(this.formId)
          this.getNews();
      }
}
</script>

<style lang="">

</style>
