<template>
<div>
    <div v-for="(post, index) in 1" :key="index" class="flex lg:flex-row md:flex-row sm:flex-col flex-col justify-between md:mb-[120px] sm:mb-6 mb-5 grid-container -z-1" :class="[index % 2 === 0 ? 'flex-row-reverse' : '']">
        <img :class="['lg:absolute md:block sm:block block self-center lg:w-[50%] md:w-[50%] sm:w-full w-full md:mb-0 mb-4 lg:inline md:hidden', index % 2 === 0 ? 'right-0 rounded-l-xl' : 'left-0 rounded-r-xl']" src="@/assets/images/mosque-a.png" alt="" uk-scrollspy="cls: uk-animation-slide-left-medium; delay:300; repeat: false">
        <div class="hidden uk-visible@m"></div>
        <div class="grid-container flex justify-between self-center" uk-scrollspy="cls: uk-animation-slide-right-medium; delay:300; repeat: false">
            <div :class="['self-center', index % 2 === 0 ? 'plan__style-right' : 'plan__style-left']">
                <div class="font-semibold md:text-2xl text-2xl text-[#112827] md:leading-[40px] mb-3">
                    Premium class
                </div>
                <div class="font-normal text-base text-[#5B5F62] mb-2">
                  {{ $t('postCard.text') }}
                </div>
                <div class="grid grid-cols-2 md:gap-y-6 gap-y-3 md:mb-[34px] mb-4">
                    <div v-for="service in posts.slice().reverse()" :key="service" class="flex space-x-4">
                        <img v-if="service.id > 10" class="w-[32px] h-[32px] self-center" :src="getImage(service.icon_files_id, service.icon_files_uid)" alt="">
                        <div v-if="service.id > 10" class="font-semibold md:text-base text-sm text-black self-center">
                            {{ service.name }}
                        </div>
                    </div>
                </div>
                <div class="flex justify-between md:space-x-32 space-x-4">
                    <router-link to="plansPage" class="hover:text-white hover:no-underline flex justify-between">
                        <div class="font-semibold text-base text-white leading-[28px] rounded-2xl bg-[#112827] md:py-[10px] py-2 md:px-8 px-4 flex justify-center cursor-pointer">
                          {{ $t('postCard.text1') }}
                        </div>
                    </router-link>
                    <!-- <div class="w-full">
                        <div class="font-semibold text-base text-white leading-[28px] rounded-2xl bg-[#112827] py-[10px] flex justify-center cursor-pointer">
                            {{ post.cost }}
                        </div>
                    </div>
                    <div class="w-full">
                        <a href="tel:+998 94 666 33 33" class="hover:no-underline">
                            <div class="font-semibold md:text-base text-sm text-white hover:text-white leading-[28px] rounded-2xl bg-[#112827] py-[10px] flex justify-center cursor-pointer">
                                +998 94 666 33 33
                            </div>
                        </a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "PostCard",
    props: {
        posts: {
            type: Array,
            default: () => [],
        },
    },
  methods: {
    getImage(id, uid) {
      return 'https://tanwir.uz:6443/gen/files/'+id+'/'+uid+'/view'
    }
  }
}
</script>

<style>
@media (min-width:1024px) {
    .plan__style-right {
        margin-right: 647px;
    }
    .plan__style-left {
        margin-left: 647px;
    }
}
@media (min-width:768px) and (max-width:1023px){
    .plan__style-right {
        margin-right: 430px;
    }
    .plan__style-left {
        margin-left: 430px;
    }
}
@media (min-width:425px) and (max-width:767px){
    .plan__style-right {
        margin-right: 0px;
    }
    .plan__style-left {
        margin-left: 0px;
    }
}
@media (min-width:375px) and (max-width:424px){
    .plan__style-right {
        margin-right: 0px;
    }
    .plan__style-left {
        margin-left: 0px;
    }
}
@media (min-width:320px) and (max-width:374px){
    .plan__style-right {
        margin-right: 0px;
    }
    .plan__style-left {
        margin-left: 0px;
    }
}
</style>
