<template>
  <div>
    <div class="flex justify-between">
        <div>
            <div class="font-semibold lg:text-[56px] md:text-[56px] sm:text-[32px] text-2xl 
                    lg:text-start md:text-start sm:text-start text-start 
                    text-[#E0B580] md:leading-[56px] sm:leading-[36px] leading-[36px] 
                    md:mb-6 sm:mb-4 mb-4">
                <slot name="menu"></slot>
            </div>
            <div class="font-normal md:text-base text-sm text-[#5B5F62] md:text-start sm:text-start text-start 
                        lg:mr-[34rem] md:leading-[26px] lg:mr-[35rem] md:mr-[15rem] mr-0rem">
                <slot name="title"></slot>
            </div>
        </div>
        <div></div>
    </div>
  </div>
</template>

<script>
export default {
    name: "Heading",
}
</script>

<style>

</style>